.bx--accordion {
  &__item--active {
    .bx--accordion__heading {
      background-color: $hover-02;
      border-radius: 5px;
      width: 100%;
      border-bottom: 1px solid transparent;

      @include respond(medium) {
        width: 753px;
      }
    }
  }
}

.bx--accordion {
  &__heading {
    padding: 0.875rem 0 !important;
    border-top: none !important;
    border-bottom: 1px solid #f2f2f3;
    &:hover {
      border-bottom: 1px solid transparent;

      &:before {
        background-color: $hover-02;
        border-radius: 5px;
        margin-left: -2px;
        width: auto;

        @include respond(medium) {
          width: 753px;
        }
      }
    }

    &:focus {
      &:before {
        border: none !important;
      }
    }
  }

  &__content {
    p {
      @include fontSize(14px);
      @include fontFamily('LB-Nu-Medium');
      @include lineheight(20px);
      @include letterspacing(0.16px);
    }
  }
  &__arrow {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.bx--accordion {
  &__item {
    list-style-type: none;
    border-top: none !important;
    border-bottom: none !important;
    &:last-child {
      border-bottom: none !important;
    }
  }
}
