.search-content-in {
  max-width: 730px;
  margin: 0 auto 0;
  padding: 0 0;

  .transfer-heading {
    .productive-heading-03 {
      @include fontSize(16px);
      @include fontFamily('LB-Nu-Semibold');
      @include lineheight(22px);
      @include letterspacing(0.16px);

      @include respond(medium) {
        @include fontSize(20px);
        @include fontFamily('LB-Nu-Semibold');
        @include lineheight(28px);
        @include letterspacing(0.16px);
      }
    }
  }

  .line {
    display: none;

    @include respond(medium) {
      display: block;
    }
  }

  .transfer-row {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond(medium) {
      margin-top: 0;
      justify-content: space-between;
      padding: 0 52px;
    }
  }

  .transfer-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchresult-icon {
    .icn-bg {
      width: 64px;
      height: 64px;
      background-color: $ui-03 !important;
      border-radius: 50%;
      padding: 16px 0;

      svg {
        width: 32px !important;
        height: 32px !important;
        color: $icon-01;
      }
    }
  }

  .pa-searchresult-favorites {
    padding-top: 64px;

    h3 {
      @include fontSize(12px);
      @include fontFamily('LB-Mt-Bold');
      @include lineheight(18px);
      @include letterspacing(0.14px);
      padding-bottom: 16px;
      color: #6f6f6f;
      margin-left: 8px;
      @include respond(medium) {
        margin-left: 0;
      }
    }

    .pa-searchresult-providers {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .all-categories__box {
        &.providers {
          width: calc(100% / 2 - 10px);
          margin: 0 16px 32px 0;
          cursor: pointer;
          text-align: left;

          &:nth-child(1n) {
            margin-left: 0;
          }

          &:nth-child(2n) {
            margin-right: 0;
          }

          &:nth-child(4n) {
            margin-right: 0;
          }

          h1 {
            color: $link-02;
            padding: 8px 0 4px 0;
          }

          p {
            color: $text-02;
          }

          &:hover {
            h1 {
              text-decoration: underline;
            }
          }

          @include respond(small) {
            width: calc(100% / 3 - 16px);
            margin: 0 16px 32px 0;
            cursor: pointer;
            text-align: left;
            &:nth-child(1n) {
              margin-left: 0;
            }

            &:nth-child(2n) {
              margin-right: 16px;
            }
            &:nth-child(3n) {
              margin-right: 0;
            }
            &:nth-child(4n) {
              margin-right: 16px;
            }
          }

          @include respond(medium) {
            width: calc(100% / 4 - 16px);
            margin: 0 16px 32px 0;
            cursor: pointer;
            text-align: left;
            &:nth-child(1n) {
              margin-left: 0;
            }

            &:nth-child(2n) {
              margin-right: 16px;
            }
            &:nth-child(3n) {
              margin-right: 16px;
            }
            &:nth-child(4n) {
              margin-right: 0;
            }
          }
        }

        // &.providers {
        //   width: 131px;
        //   margin: 0 16px 32px 16px;
        //   text-align: center;
        //   &:nth-child(1n) {
        //     margin-left: 0;
        //   }

        //   &:nth-child(2n) {
        //     margin-right: 0;
        //   }

        //   &:nth-child(4n) {
        //     margin-right: 0;
        //   }
        //   p {
        //     margin-bottom: 0;
        //   }
        //   @include respond(small) {
        //     width: 161px;
        //     &:nth-child(1n) {
        //       margin-left: 0;
        //     }
        //     &:nth-child(2n) {
        //       margin-right: 16px;
        //     }

        //     &:nth-child(4n) {
        //       margin-right: 0;
        //     }
        //   }
        //   @include respond(medium) {
        //     width: 161px;
        //     &:nth-child(1n) {
        //       margin-left: 0;
        //     }
        //     &:nth-child(2n) {
        //       margin-right: 16px;
        //     }

        //     &:nth-child(4n) {
        //       margin-right: 0;
        //     }
        //   }
        // }
      }

      .pa-block__favorites-in {
        //  width: 136px;
        height: 63px;

        img {
          width: 136px;
          height: 63px;
        }

        @include respond(small) {
          width: auto;
          height: 75px;

          img {
            width: 161px;
            height: 75px;
          }
        }
        @include respond(medium) {
          width: 161px;
          height: 75px;

          img {
            width: 161px;
            height: 75px;
          }
        }
      }

      @include respond(medium) {
        margin: 0 -8px;
      }
    }
  }

  @include respond(medium) {
    padding: 0 16px;
  }
}
.trans-search {
  z-index: 1050;
  .pa-searchresult-contnet {
    animation: fadeIn ease 0.5s;
    -webkit-animation: fadeIn ease 0.5s;
    -moz-animation: fadeIn ease 0.5s;
    -o-animation: fadeIn ease 0.5s;
    -ms-animation: fadeIn ease 0.5s;
    @include respond(medium) {
      top: 58px;
    }
  }
}
.pa-searchresult-contnet {
  width: 100%;
  background-color: $background-white;
  position: absolute;
  top: 50px;
  z-index: 3;
  border-radius: 10px;
  padding: 16px 0;
  box-shadow: $shadow-02-white;

  // animation: fadeIn ease 0.5s;
  // -webkit-animation: fadeIn ease 0.5s;
  // -moz-animation: fadeIn ease 0.5s;
  // -o-animation: fadeIn ease 0.5s;
  // -ms-animation: fadeIn ease 0.5s;

  .pa-searchresult-providers {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .pa-searchresult-block {
      width: calc(100% / 2 - 16px);
      margin: 0 8px 16px 8px;

      &:hover {
        &--favorites {
          background-color: $hover-03;

          &.active {
            background-color: $active-03;
          }

          &.selected {
            background-color: $active-03;
          }
        }

        .helper-text-01 {
          color: $text-01;
        }
      }
      &--favorites {
        height: 63px;
        background-color: $enable-03;
        border-radius: 8px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s linear;
        overflow: hidden;
        cursor: pointer;

        // &:hover {
        //   background-color: $hover-03;
        // }

        // &.active {
        //   background-color: $active-03;
        // }

        // &.selected {
        //   background-color: $active-03;
        // }

        img {
          width: 131px;
          height: 63px;
          // mix-blend-mode: darken;
        }

        @include respond(medium) {
          height: 75px;
        }
      }

      h1.helper-text-01 {
        margin-bottom: 16px;
        transition: 0.3s;
      }

      @include respond(medium) {
        //height: 83px;
        width: calc(100% / 4 - 16px);
        margin: 0 8px 16px 8px;
        img {
          width: 161px;
          height: 75px;
          // mix-blend-mode: darken;
        }
      }
    }
  }

  h3 {
    @include fontSize(12px);
    @include fontFamily('LB-Mt-Bold');
    @include lineheight(18px);
    @include letterspacing(0.14px);
    color: $text-02 !important;
    padding-bottom: 8px;
  }

  @include respond(medium) {
    top: 50px;
    width: 740px;
    padding: 32px 16px 16px 16px;
  }
}

.pa-searchresult-providers {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .pa-searchresult-block {
    width: calc(100% / 2 - 16px);
    margin: 0 8px 16px 8px;

    &:hover {
      &--favorites {
        background-color: $hover-03;

        &.active {
          background-color: $active-03;
        }

        &.selected {
          background-color: $active-03;
        }
      }

      .helper-text-01 {
        color: $text-01;
      }
    }

    &--favorites {
      height: 75px;
      background-color: $enable-03;
      border-radius: 10px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s linear;
      overflow: hidden;
      cursor: pointer;

      &:hover {
       // background-color: $hover-03;
      }

      &.active {
        background-color: $active-03;
      }

      &.selected {
        background-color: $active-03;
      }

      img {
        width: 161px;
        height: 75px;
        // mix-blend-mode: darken;
      }
    }
    h1.helper-text-01 {
      margin-bottom: 16px;
      transition: 0.3s;
    }
    @include respond(medium) {
      width: calc(100% / 4 - 14px);
      margin: 0 8px 16px 8px;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
