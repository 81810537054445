.bx--modal-container {
  border-radius: 6px;
  background-color: $background-white;
  box-shadow: $shadow-24-white;
  width: 92%;
  height: auto;
  margin: 12px;
  top: auto;

  @include respond(medium) {
    margin: 0;
    &.bx--modal-container--sm {
      width: 31.25%;
    }
  }
}
.bx--modal-content--overflow-indicator {
  display: none;
}

.bx--modal-content {
  padding: 16px 16px 0;
  margin-bottom: 2rem;
  h3 {
    @include fontSize(16px);
    @include fontFamily('LB-Nu-Semibold');
    @include lineheight(24px);
    @include letterspacing(0);
    padding-bottom: 24px;
    text-align: center;
    @include respond(medium) {
      @include fontSize(20px);
      @include fontFamily('LB-Nu-Semibold');
      @include lineheight(28px);
      @include letterspacing(0.16px);
      padding-bottom: 32px;
      text-align: left;
    }
  }
  @include respond(medium) {
    padding: 32px 32px 0;
    margin-bottom: 3rem;
  }
}
.pa-modal {
  .bx--modal-content {
    padding: 24px 24px 0;
    margin-bottom: 1.5rem;
    h3 {
      padding-bottom: 16px;
    }
    @include respond(medium) {
      padding: 32px 32px 0;
      margin-bottom: 2rem;
    }
  }
  .bx--modal-footer {
    border-top: 1px solid $ui-04;
    .btn-one {
      flex: 0 1 auto;
      padding-bottom: 1rem;
      padding-right: 1rem;
      justify-content: center;
      &.btn-secondary {
        &:hover {
          color: $text-01 !important;
        }
      }
    }
  }
}
.pa-mail-modal {
  .bx--modal-footer {
    border-top: 1px solid $ui-04;
    .btn-one {
      flex: 0 1 auto;
      padding-bottom: 1rem;
      padding-right: 1rem;
      justify-content: center;
      &.btn-secondary {
        &:hover {
          color: $text-01 !important;
        }
      }
    }
  }
}

.pa-mail-modal {
  .form-group {
    margin-bottom: 0;
    margin-top: 24px;
    @include respond(medium) {
      margin-top: 32px;
    }
  }
}

.bx--body--with-modal-open {
  padding-right: 6px;
  .pa-header-inside__top {
    padding-right: 6px;
  }
}

.pa-insurance-modal {
  .bx--modal-container {
    max-width: 480px;
    @include fontSize(14px);
    @include lineheight(20px);
    @include letterspacing(0.16px);
  }

  .bx--modal-content {
    padding: 24px 16px 0 16px;
    margin-bottom: 16px;
    @include respond(medium) {
      padding: 32px 32px 0;
      margin-bottom: 2rem;
    }
  }

  .bx--modal-footer {
    .btn-one {
      &.btn-secondary{
        @extend .body-short-02;
        background-color: transparent;
        border: none;
      }

      &.button-primary {
        color: $han-blue-60;
        background-color: transparent;

        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}