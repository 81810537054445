.bx--radio-button-group {
  .bx--label {
    @extend .button-and-link-01;
    color: $text-01;
  }

  .bx--radio-button-wrapper {

    input{
      display: none;
    }
  }

  .bx--radio-button__label {
    display: flex;
    align-items: center;

    span:last-child {
      @extend .body-short-02;
    }
  }

  .bx--radio-button__appearance {
    height: 24px;
    width: 24px;
    background-color: $background-white;
    border: 1px solid $neutral-40;
    border-radius: 50%;
    position: relative;
  }

  input[type="radio"]:checked + .bx--radio-button__label .bx--radio-button__appearance {
    &::before {
      background: none;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      background: url("../../images/icons/checkmark.svg");
      width: 24px;
      height: 24px;
    }
  }
}