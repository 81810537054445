//Headings

.productive-heading-01 {
  @include fontSize(14px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(20px);
  @include letterspacing(0.16px);
}

.productive-heading-02 {
  @include fontSize(16px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(24px);
  @include letterspacing(0);
}

.productive-heading-03 {
  @include fontSize(20px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(28px);
  @include letterspacing(0.16px);
}

.productive-heading-04 {
  @include fontSize(28px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(36px);
  @include letterspacing(0);
}

.productive-heading-05 {
  @include fontSize(32px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(40px);
  @include letterspacing(0);
}

.productive-heading-06 {
  @include fontSize(42px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(50px);
  @include letterspacing(0);
}

.productive-heading-07 {
  @include fontSize(54px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(64px);
  @include letterspacing(0);
}

//Productive styles
.caption-01 {
  @include fontSize(12px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(16px);
  @include letterspacing(0.24px);
  color: $text-01;
}

.code-01 {
  @include fontSize(12px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(16px);
  @include letterspacing(0.24px);
  color: $text-01;
}

.code-02 {
  @include fontSize(14px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(20px);
  @include letterspacing(0);
  color: $text-01;
}

.label-01 {
  @include fontSize(12px);
  @include fontFamily('LB-Nu-Bold');
  @include lineheight(16px);
  @include letterspacing(0.24px);
}

.caption-01 {
  @include fontSize(12px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(16px);
  @include letterspacing(0.24px);
  color: $text-01;
}

.helper-text-01 {
  @include fontSize(12px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(16px);
  @include letterspacing(0.24px);
  color: $text-02;
}

//body

.body-short-01 {
  @include fontSize(12px);
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(16px);
  @include letterspacing(0.24px);
}

.body-short-02 {
  @include fontSize(14px);
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(18px);
  @include letterspacing(0.16px);
}

.body-short-03 {
  @include fontSize(16px);
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(22px);
  @include letterspacing(0);
}

.body-long-01 {
  @include fontSize(12px);
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(18px);
  @include letterspacing(0.24px);
}

.body-long-02 {
  @include fontSize(14px);
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(20px);
  @include letterspacing(0.16px);
}

.body-long-03 {
  @include fontSize(16px);
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(24px);
  @include letterspacing(0);
}

//Button-and-link

.button-and-link-01 {
  @include fontSize(14px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(18px);
  @include letterspacing(0.16px);
}

.button-and-link-02 {
  @include fontSize(14px);
  @include fontFamily('LB-Nu-Bold');
  @include lineheight(18px);
  @include letterspacing(0.16px);
}

//display

.display-04 {
  @include fontSize(84px);
  @include fontFamily('LB-Mt-Regular');
  @include lineheight(94px);
}
