//default btn

.btn {
  @include fontSize(14px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(18px);
  @include letterspacing(0.16px);
  align-items: center;
  border: 0;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  flex-shrink: 0;
  margin: 0;
  //max-width: 20rem;
  min-height: 3rem;
  outline: none;
  padding: calc(0.875rem - 3px) 16px calc(0.875rem - 3px) 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9),
    box-shadow 70ms cubic-bezier(0, 0, 0.38, 0.9),
    border-color 70ms cubic-bezier(0, 0, 0.38, 0.9),
    outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  vertical-align: initial;
  vertical-align: top;

  .btn-icon {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    right: 1rem;
  }
}

//end
.btn-primary {
  background-color: $enable-primary-01;
  border-color: $enable-primary-01;
  color: $inverse-text-01;

  .btn-icon {
    fill: currentColor;
  }

  &:hover {
    background-color: $hover-primary-02;
  }

  &:active {
    background-color: $active-primary-03;
  }

  &:disabled {
    background-color: $disabled-01;
    border-color: $disabled-01;
    color: $text-disabled;
    cursor: not-allowed;

    .btn-icon {
      fill: currentColor;
    }

    &:hover {
      background-color: $disabled-01;
    }

    &:active {
      background-color: $disabled-01;
    }

    .btn-loader {
      top: 43%;

      .loader {
        background-color: $b-ui-05;
        &::before {
          background-color: $ui-01 !important;
        }
      }
    }
  }
  //btn disbale but not disbale style
  &.btn-load {
    background-color: $enable-primary-01 !important;
    border-color: $enable-primary-01 !important;
    color: $inverse-text-01 !important;

    .btn-icon {
      fill: currentColor;
    }

    &:hover {
      background-color: $hover-primary-02;
    }

    &:active {
      background-color: $active-primary-03;
    }
  }
}

.btn-secondary {
  background-color: $enable-secondary-01;
  border-color: $enable-secondary-01;
  color: $text-01;

  .btn-icon {
    fill: currentColor;
  }

  &:hover {
    background-color: $hover-secondary-02;
    color: $text-01;
  }
  &:focus {
    box-shadow: none;
    border-color: $enable-secondary-01 !important;
  }

  &:active {
    background-color: $active-secondary-03;
  }

  &:disabled {
    background-color: $disabled-01;
    border-color: $disabled-01;
    color: $text-disabled;
    cursor: not-allowed;

    .btn-icon {
      fill: currentColor;
    }

    &:hover {
      background-color: $disabled-01;
    }

    &:active {
      background-color: $disabled-01;
    }
  }
  .btn-loader {
    top: 46%;
    .loader {
      background-color: $b-ui-05;
      &::before {
        background-color: #76767f !important;
        box-shadow: none;
      }
    }
  }

  //btn disbale but not disbale style
  &.btn-load {
    background-color: $enable-secondary-01 !important;
    border-color: $enable-secondary-01 !important;
    color: $text-01 !important;

    .btn-icon {
      fill: currentColor;
    }

    &:hover {
      background-color: $hover-primary-02;
    }

    &:active {
      background-color: $active-primary-03;
    }
  }
}

.btn-tertiary {
  background-color: $enabled-tertiary-01;
  border-color: $enabled-tertiary-01;
  color: $inverse-text-01;

  .btn-icon {
    fill: currentColor;
  }

  &:hover {
    background-color: $hover-tertiary-02;
  }

  &:active {
    background-color: $active-tertiary-03;
  }

  .btn-loader {
    top: 43%;

    .loader {
      background-color: $b-ui-05;
      &::before {
        background-color: $ui-01 !important;
      }
    }
  }

  &:disabled {
    background-color: $disabled-01;
    border-color: $disabled-01;
    color: $text-disabled;
    cursor: not-allowed;

    .btn-icon {
      fill: currentColor;
    }

    &:hover {
      background-color: $disabled-01;
    }

    &:active {
      background-color: $disabled-01;
    }
  }
  //btn disbale but not disbale style
  &.btn-load {
    background-color: $enabled-tertiary-01 !important;
    border-color: $enabled-tertiary-01 !important;
    color: $inverse-text-01 !important;

    .btn-icon {
      fill: currentColor;
    }

    &:hover {
      background-color: $hover-primary-02;
    }

    &:active {
      background-color: $active-primary-03;
    }
  }
}

.btn-ghost {
  background-color: transparent;
  border-color: transparent;
  color: $link-02;

  .btn-icon {
    fill: currentColor;
  }

  &:hover {
    background-color: $ui-02;
  }

  &:active {
    background-color: $ui-02;
  }
}

.btn-danger {
  background-color: $enabled-danger-01;
  border-color: $enabled-danger-01;
  color: $inverse-text-01;

  .btn-icon {
    fill: currentColor;
  }

  &:hover {
    background-color: $hover-danger-02;
  }

  &:active {
    background-color: $active-danger-03;
  }
}

.btn-ghost-danger {
  background-color: $enable-ghost-danger-01;
  border-color: $enable-ghost-danger-01;
  color: $danger;

  .btn-icon {
    fill: currentColor;
  }

  &:hover {
    background-color: $hover-ghost-danger-02;
  }

  &:active {
    background-color: $active-ghost-danger-03;
  }
}

.btn-disabled {
  background-color: $disabled-01;
  border-color: $disabled-01;
  color: $text-disabled;
  cursor: not-allowed;

  .btn-icon {
    fill: currentColor;
  }

  &:hover {
    background-color: $disabled-01;
  }

  &:active {
    background-color: $disabled-01;
  }
}

//icon-only
.btn-icon-only {
  padding-left: 1rem;
  padding-right: 1rem;

  .btn-icon {
    position: static;
  }

  &:disabled {
    background-color: $disabled-01;
    border-color: $disabled-01;
    color: $text-disabled;
    cursor: not-allowed;

    .btn-icon {
      fill: $disabled-02;
    }

    &:hover {
      background-color: $disabled-01;
    }

    &:active {
      background-color: $disabled-01;
    }
  }
}

/*Button default — White theme
(height: 48px)
*/
.btn-default {
  &.btn-with-icon {
    padding: calc(0.375rem - 3px) 48px calc(0.375rem - 3px) 16px;
  }
}

.btn-default {
  &.btn-icon-only {
    min-height: 3rem;
    height: 3rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

/*
Button field — White theme
(height: 40px)
*/

.btn-md {
  min-height: 2.5rem;
  height: 2.5rem;
  padding: calc(0.675rem - 3px) 16px calc(0.675rem - 3px) 16px;

  &.btn-with-icon {
    padding: calc(0.675rem - 3px) 48px calc(0.675rem - 3px) 16px;
  }

  &.btn-icon-only {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  // &.btn-ghost {
  //   padding: calc(0.675rem - 3px) 16px;
  // }

  .btn-loader {
    top: 43%;

    .loader {
      width: 1.24rem;
      height: 0.375rem;
      background-color: $b-ui-05;
      &::before {
        width: 0.625rem;
        height: 0.375rem;
        left: 0;
        background-color: #fff;
        box-shadow: $shadow-01-white;
        -webkit-animation: pushmobilesmall 1.5s infinite linear;
        animation: pushmobilesmall 1.5s infinite linear;
      }
    }
  }
}

/*Button small — White theme
(height: 32px)
*/

.btn-sm {
  width: 2rem;
  min-height: 2rem;
  padding: calc(0.375rem - 3px) 16px calc(0.375rem - 3px) 16px;

  &.btn-with-icon {
    padding: calc(0.375rem - 0px) 48px calc(0.375rem - 2px) 16px;
  }

  &.btn-icon-only {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }

  .btn-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  // &.btn-ghost {
  //   padding: calc(0.375rem - 3px) 16px;
  // }
}

.btn-w {
  width: calc(100% - 56px);
}

.btn-block {
  .btn-loader {
    .loader {
      background-color: $b-ui-05;
      &::before {
        background-color: #fff;
        border-radius: 4px;
        box-shadow: $shadow-01-white;
        box-shadow: none;
      }
    }
  }
}

//black button

.btn-black {
  background-color: #161616;
  min-height: 3.5rem;
  color: #fff;
  @include fontSize(16px);
  @include fontFamily('LB-Nu-Semibold');
  @include lineheight(24px);
  @include letterspacing(0.16px);
  padding: calc(0.875rem - 3px) 32px calc(0.875rem - 3px) 32px;
}
