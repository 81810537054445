.knobs {
  padding-top: 23px;
  padding-bottom: 25px;
  @include border-bottom;

  @include respond(medium) {
    border: none;
    padding-top: 0;
    padding-bottom: 18px;
    margin-top: -6px;
  }

  &__list {
    display: flex;
    justify-content: center;
    gap: 19px;

    @include respond(medium) {
      gap: 24px;
      justify-content: flex-start;
      margin-left: 122px;
    }

    &--item {
      background: none;
      border: none;
      position: relative;

      @include respond(medium) {
        padding: 13px 0;

        &:not(:last-child) {
          width: 150px;

          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            height: .5px;
            width: 23px;
            background-color: $neutral-40;
          }
        }
      }


      button {
        border: none;
        background: none;
        display: flex;
        align-items: center;
        @extend .body-long-01;

        @include respond(medium) {
          @include fontSize(14px);
          @include letterspacing(0.16px);
        }

        span {
          display: inline-block;
          height: 16px;
          width: 16px;
          border: 1px solid $neutral-40;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }

    &--item.active,
    &--item.filled {
      cursor: pointer;

      button {
        color: $text-light-01;
        cursor: inherit;
      }

      span {
      }
    }

    &--item.filled {
      button {
        span {
          background-image: url("../../images/icons/checkmark-green.svg");
          background-position: center;
          border: none;
        }
      }
    }

    &--item.disabled {
      cursor: not-allowed;

      button {
        color: $text-light-02;
        cursor: inherit;

        span {
        }
      }
    }
  }
}