.pa-basket-left {
  width: 100%;
  margin-right: 0;

  .pa-basket-row {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    padding: 0 16px 8px 16px;
    margin: 16px -16px 32px;

    &::after {
      content: '';
      height: 1px;
      width: 100%;
      background-color: $ui-04;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .icn-trash {
      color: $support-error-02;
      // margin-top: 41px;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .icn-mobile {
      position: absolute;
      right: 22px;
      margin-top: 20px;
    }

    @include respond(small) {
      padding: 0 32px 32px 32px;
      margin: 16px -32px 32px;

      .icn-mobile {
        position: absolute;
        right: 32px;
        margin-top: 20px;
      }
    }
    @include respond(medium) {
      flex-direction: row;
      align-items: center;
      margin: 0 0 16px;
      padding: 0 0 16px 0;

      &::after {
        content: '';
        width: 74%;
      }

      .icn-mobile {
        position: absolute;
        right: 32px;
        margin-top: 20px;
      }
    }
  }

  .providers {
    &__box {
      width: 136px;
      height: 63px;
      margin-right: 32px;

      img {
        width: 136px;
        height: 63px;
      }

      @include respond(small) {
        width: 204px;
        height: 95px;

        img {
          width: 204px;
          height: 95px;
        }
      }
    }

    &__details {
      color: $text-01;
      padding: 16px 0 24px;

      .productive-heading-01 {
        color: $text-02;
      }

      .payable {
        color: $text-01;

        .pa-btn-link {
          color: $link-02;
          margin-left: 16px;

          &::before {
            content: '';
            background-color: $icon-03;
            width: 3px;
            height: 3px;
          }
        }
      }

      h3 {
        @include fontSize(14px);
        @include fontFamily('LB-Nu-Bold');
        @include lineheight(18px);
        @include letterspacing(0.16px);
      }

      @include respond(medium) {
        padding: 10px 0 16px;
        flex: 1 1;

        h3 {
          @include fontSize(16px);
          @include fontFamily('LB-Nu-Semibold');
          @include lineheight(22px);
          @include letterspacing(0.16px);
        }
      }

      .pa-abonent-info {
        @include respond(medium) {
          max-width: 467px;
          min-width: 338px;
          margin-right: 16px;
        }

        &:hover {
          text-decoration: underline;
        }

        .line-clamp {
          width: 280px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -ms-text-overflow: ellipsis;
          display: block;

          @include respond(small) {
            width: auto;
          }
          @include respond(medium) {
            width: auto;
            white-space: unset;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    &__filled {
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: 0;

      .form-group {
        max-width: none;
        margin-bottom: 0;
        width: 100%;

        @include respond(medium) {
          .form-group {
            max-width: 162px;
            margin-bottom: 0;
            width: auto;
          }
        }
      }

      @include respond(medium) {
        width: 204px;
        margin-left: auto;
      }
    }

    &__filled--utilities {
      width: 100%;

      @include respond(medium) {
        width: 162px;
        margin-left: auto;
        &.basket-utilities {
          width: 204px;
          margin-left: auto;
          .pa-text-input__field-outer-wrapper {
            flex-direction: row;
            align-items: center;
          }
        }
      }

      &.united-utility {
        width: 100%;
        margin-left: auto;
        .pa-text-input__field-outer-wrapper {
          flex-direction: row;
          align-items: center;
        }

        @include respond(medium) {
          width: 204px;
        }
      }
    }
  }

  .pa-method-payment {
    max-width: none;
    margin-left: auto;

    .pa-basket-commission {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .btn {
      width: 100%;
      margin-left: 0;
    }

    .form-group {
      width: 100%;
    }

    &:nth-child(2n) {
      flex-direction: column;
    }

    .pa-basket-pay {
      margin-top: 32px;
      margin-bottom: 48px;

      & > div {
        @include fontSize(14px);
        @include fontFamily('LB-Nu-Bold');
        @include lineheight(18px);
        @include letterspacing(0.16px);

        &:nth-child(1) {
          & > div:first-child {
            color: #424245;
          }
        }

        &:nth-child(2) {
          & > div:first-child {
            color: #424245;
          }
        }
      }

      @include respond(medium) {
        margin-top: 32px;
        margin-bottom: 0;

        & > div {
          @include fontSize(16px);
          @include fontFamily('LB-Nu-Semibold');
          @include lineheight(24px);
          @include letterspacing(0);

          &:nth-child(1) {
            & > div:first-child {
              color: #161616;
            }
          }

          &:nth-child(2) {
            & > div:first-child {
              color: #161616;
            }
          }
        }

        .total-payable {
          @include fontSize(20px);
          @include fontFamily('LB-Nu-Semibold');
          @include lineheight(28px);
          @include letterspacing(0.16px);
        }
      }
    }

    @include respond(medium) {
      max-width: 414px;
      margin-left: auto;
      margin-bottom: 0;
      .pa-basket-commission {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
      .form-group {
        width: auto;
      }

      .btn {
        width: 98px;
        margin-left: 16px;
      }
      .bx--dropdown {
        width: 300px;
      }
    }
  }

  @include respond(medium) {
    width: 74%;
    margin-right: 64px;
  }
}

.pa-basket-right {
  width: 256px;

  img{
    border-radius: 10px;
  }
}
