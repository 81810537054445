.mobile-dropdown-menu {
  max-width: 288px;
  margin-bottom: 24px;

  .bx--dropdown {
    border-bottom: none;
    background-color: transparent;

    &:hover {
      border-bottom: none;
      background-color: transparent;
    }

    &:focus {
      outline: none;
    }

    .bx--list-box__field {
      padding-left: 0;

      &:focus {
        outline: none;
      }
    }

    .bx--list-box__menu-icon {
      width: 1.25rem;
      height: 1.25rem;
      right: 27px;
      top: 10px;
    }

    .bx--list-box__label {
      @include fontSize(14px);
      @include fontFamily('LB-Nu-Medium');
      @include lineheight(20px);
      @include letterspacing(0.16px);
    }
  }

  .bx--list-box__menu {
    box-shadow: $shadow-24;
    border-radius: 6px;
    width: 288px;
    background-color: #fff;

    &:focus {
      outline: none;
    }
  }

  .bx--list-box__menu-item {
    &:hover {
      background-color: $selected-03 !important;

      .bx--list-box__menu-item__option {
        color: $text-01;
      }
    }
  }

  .bx--dropdown--open.bx--list-box--expanded {
    .bx--list-box__menu {
      padding: 8px 0;
      border: 0.5px solid rgba(57, 57, 57, 0.08);
    }
  }

  .bx--list-box__menu-item__option {
    border-top-color: $ui-04;
    padding: 0.6875rem 0;
    margin: 0 0 0 1rem;
    @include fontSize(14px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(20px);
    @include letterspacing(0.16px);
    color: $text-02;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
.bx--dropdown__wrapper {
  position: relative;
  width: 100%;
  .bx--label {
    margin-bottom: 0.5rem;
    display: inline-block;
    color: $text-01;
    @include fontSize(12px);
    @include fontFamily('LB-Nu-Bold');
    @include lineheight(16px);
    @include letterspacing(0.24px);
  }

  .bx--list-box__field {
    padding-right: 3.125rem;
  }
  .bx--dropdown--invalid {
    outline: 2px solid #f35815;
    outline-offset: -2px;

    .bx--list-box__invalid-icon {
      position: absolute;
      right: 3.125rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 0;
      width: 1.5rem;
      height: 1.5rem;
      fill: #cc4a12;
    }

    .bx--list-box__field {
      border: 1px solid transparent !important;
    }
  }
  .bx--form-requirement {
    @include fontSize(12px);
    @include fontFamily('LB-Nu-Semibold');
    @include lineheight(16px);
    @include letterspacing(0.24px);
    color: $text-04-danger;
    padding-top: 4px;
  }

  .bx--form__helper-text {
    @include fontSize(12px);
    @include fontFamily('LB-Nu-Semibold');
    @include lineheight(16px);
    @include letterspacing(0.24px);
    color: $text-02;
    padding-top: 0.25rem;
  }
}
.pa-default-dropdown__wrapper {
  position: relative;
  width: 100%;
  .bx--label {
    margin-bottom: 0.5rem;
    display: inline-block;
    color: $text-01;
    @include fontSize(12px);
    @include fontFamily('LB-Nu-Bold');
    @include lineheight(16px);
    @include letterspacing(0.24px);
  }

  .bx--list-box__field {
    padding-right: 5rem;
  }
  .bx--dropdown--invalid {
    outline: 2px solid #f35815;
    outline-offset: -2px;

    .bx--list-box__invalid-icon {
      position: absolute;
      right: 3.125rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 0;
      width: 1.5rem;
      height: 1.5rem;
      fill: #cc4a12;
    }
  }
  .bx--form-requirement {
    @include fontSize(12px);
    @include fontFamily('LB-Nu-Semibold');
    @include lineheight(16px);
    @include letterspacing(0.24px);
    color: $text-04-danger;
    padding-top: 4px;
  }
}
.pa-default-dropdown {
  height: 3rem;
  max-height: inherit;
  &.bx--dropdown {
    border-bottom: none;
    background-color: $field-01;
    border-radius: 6px !important;

    &:hover {
      background-color: transparent;
    }

    .bx--list-box__field {
      border: 1px solid $ui-05;
      border-radius: 6px !important;
      &:focus {
        border: 2px solid $focus-02;
        outline: none;
        padding: 0.875rem 3.438rem 0.875rem 0.938rem;
        .bx--list-box__menu-icon {
          right: 0.938rem;
        }
      }
    }

    .bx--list-box__menu-icon {
      width: 1.25rem;
      height: 1.25rem;
    }

    .bx--list-box__label {
      @include fontSize(14px);
      @include fontFamily('LB-Nu-Medium');
      @include lineheight(20px);
      @include letterspacing(0.16px);
    }
  }
  &.bx--dropdown--disabled {
    background-color: $disabled-04;
    cursor: not-allowed;
    border: 1px solid $field-disabled-border;
    outline: none;
    color: $disabled-03;

    &::placeholder {
      color: $text-disabled-01;
    }

    &:hover {
      background-color: $disabled-04;
    }
  }
  .bx--list-box__menu {
    box-shadow: $shadow-24;
    border-radius: 6px;
    width: auto;
    background-color: #fff;
    margin: 2px -1px;
    .bx--list-box__menu-item--active {
      background-color: $hover-02 !important;
      border-bottom-color: transparent !important;
      .bx--list-box__menu-item__option {
        color: $text-01;
      }
    }
    &:focus {
      outline: none;
    }
  }

  .bx--list-box__menu-item {
    &:hover {
      background-color: $hover-02 !important;

      .bx--list-box__menu-item__option {
        color: $text-01;
      }
    }
  }

  &.bx--dropdown--open.bx--list-box--expanded {
    .bx--list-box__menu {
      padding: 8px 0;
      border: 0.5px solid rgba(57, 57, 57, 0.08);
    }
  }

  .bx--list-box__menu-item__option {
    border-top-color: $hover-02;
    @include fontSize(14px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(20px);
    @include letterspacing(0.16px);
    color: $text-02;
    margin: 0 0 0 1rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.bx--list-box__menu-item__option,
.bx--list-box__menu-item {
  height: 3rem !important;
}
.bx--list-box__menu-item__option {
  padding: 0.875rem 0 0.6875rem 0;
}

.bx--combo-box[data-invalid] .bx--text-input:not(:focus),
.bx--list-box[data-invalid]:not(:focus),
.bx--number[data-invalid] input[type='number']:not(:focus),
.bx--select-input__wrapper[data-invalid] .bx--select-input:not(:focus),
.bx--text-area__wrapper[data-invalid] > .bx--text-area--invalid:not(:focus),
.bx--text-input__field-wrapper[data-invalid]
  > .bx--text-input--invalid:not(:focus),
input[data-invalid]:not(:focus) {
  border: 2px solid #f35815;
  outline: none;

  .bx--list-box__menu-icon {
    right: 0.938rem;
  }

  .bx--list-box__field {
    padding-left: 0.938rem;
  }
}

input[data-invalid] ~ .bx--form-requirement,
.bx--number[data-invalid] .bx--number__input-wrapper ~ .bx--form-requirement,
.bx--date-picker-input__wrapper ~ .bx--form-requirement,
.bx--date-picker-input__wrapper--invalid ~ .bx--form-requirement,
.bx--time-picker--invalid ~ .bx--form-requirement,
.bx--text-input__field-wrapper[data-invalid] ~ .bx--form-requirement,
.bx--text-area__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--select-input__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--time-picker[data-invalid] ~ .bx--form-requirement,
.bx--list-box[data-invalid] ~ .bx--form-requirement {
  color: #cc4a12 !important;
}

.bx--list-box__menu-icon--open {
  transform: none !important;
}
