//MEDIA QUERY MANAGER
/*
0 - 320px:  xsmall
320 - 672px:  small
672 - 1056px: medium
1056 - 1312px: large
1584 + xlarge 
*/

/* $breakpoint argument choices
 - xsmall
 - small
 - medium
 - large
 - xlarge
 */

@mixin respond($breakpoint) {
  @if $breakpoint == xsmall {
    @media (min-width: 20rem) {
      @content;
    }
  }
  @if $breakpoint == small {
    @media (min-width: 42rem) {
      @content;
    }
  }
  @if $breakpoint == medium {
    @media (min-width: 66rem) {
      @content;
    }
  }
  @if $breakpoint == large {
    @media (min-width: 82rem) {
      @content;
    }
  }
  @if $breakpoint == xlarge {
    @media (min-width: 99rem) {
      @content;
    }
  }
}

//
// Mixins: Dark Mode Controll
//

@mixin dark-mode {
  @if $enable-dark-mode {
    .dark-mode {
      @content;
    }
  }
}

// convert px to rem
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

// font-size: $size;
@mixin fontSize($size) {
  font-size: calculateRem($size);
}

// font Family
@mixin fontFamily($font) {
  font-family: $font, Arial, sans-serif;
}

// font weight
@mixin fontweight($weight) {
  font-weight: $weight;
}

// line height
@mixin lineheight($size) {
  line-height: calculateRem($size);
}

// letter spacing
@mixin letterspacing($size) {
  letter-spacing: $size;
}

// width: $size; height: $size;
@mixin iconSize($size) {
  width: calculateRem($size);
  height: calculateRem($size);
}

//position  absolute;
@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//placeholder
@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

@mixin text-ellipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

$margin-gen-list: 0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 20 22 24 25 26
  30 32 34 36 35 40 42 44 45 46 48 50 55 56 58 60 64 65 70 72 75 76 80 85 90 95 96
  100 120 144 150 155 200 330;
@each $margin-gen in $margin-gen-list {
  .m-#{$margin-gen} {
    margin: #{$margin-gen}px;
  }
  .mt-#{$margin-gen} {
    margin-top: #{$margin-gen}px !important;
  }
  .mb-#{$margin-gen} {
    margin-bottom: #{$margin-gen}px;
  }
  .mr-#{$margin-gen} {
    margin-right: #{$margin-gen}px;
  }
  .ml-#{$margin-gen} {
    margin-left: #{$margin-gen}px !important;
  }
  .p-#{$margin-gen} {
    padding: #{$margin-gen}px;
  }
  .pt-#{$margin-gen} {
    padding-top: #{$margin-gen}px;
  }
  .pb-#{$margin-gen} {
    padding-bottom: #{$margin-gen}px;
  }
  .pr-#{$margin-gen} {
    padding-right: #{$margin-gen}px;
  }
  .pl-#{$margin-gen} {
    padding-left: #{$margin-gen}px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


// border bottom
@mixin border-bottom($width: 1px, $color: $soft-black-border) {
  border-bottom: $width solid $color;
}