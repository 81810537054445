// COLORS
// --------------------------------------------------------

//background

$background-white: #fff;
$background-black: #000;
$green: #00896a;
$black: #000000;

//Field
$field-01: #ffffff;
$field-02: #ebebed;
$field-disabled-02: #e1e1e3;
$filed-filled: #f5f5f7;
$filed-blur-03: rgba(255, 255, 255, 0.72);

.field-disabled-04 {
  border: 0.5px solid rgba(225, 225, 227, 1);
  background-color: rgba(245, 245, 247, 1);
}

//icon
$icon-01: #161616;
$icon-02: #6f6f6f;
$icon-03: #ababac;
$icon-04: #cdcdcf;
$icon-05: #ffffff;
$icon-06: #e3192a;
//inverse
$inverse-text-01: #ffffff;
$inverse-danger-02: #f68a5b;
$inverse-success-03: #4dac94;
$inverse-warning-04: #f5d45f;
$inverse-info-05: #bf4c55;
$inverse-link-06: #4dac94;

//Link
$link-01: #1152c6;
$link-02: #161616;
$danger: #d65716;
$link-hover-01: #1152c6;
//Shadow Grey
$shadow-01: 0px 1px 1px 0px rgba(0, 0, 0, 0.07),
  0px 1px 3px 0px rgba(0, 0, 0, 0.05);
$shadow-02: 0px 2px 2px 0px rgba(0, 0, 0, 0.07),
  0px 1px 5px 0px rgba(0, 0, 0, 0.05);
$shadow-03: 0px 3px 4px 0px rgba(0, 0, 0, 0.07),
  0px 1px 8px 0px rgba(0, 0, 0, 0.05);
$shadow-04: 0px 4px 5px 0px rgba(0, 0, 0, 0.07),
  0px 2px 4px -1px rgba(0, 0, 0, 0.05);
$shadow-06: 0px 6px 10px 0px rgba(0, 0, 0, 0.07),
  0px 3px 5px -1px rgba(0, 0, 0, 0.05);
$shadow-08: 0px 8px 10px 1px rgba(0, 0, 0, 0.07),
  0px 5px 5px -3px rgba(0, 0, 0, 0.05);
$shadow-09: 0px 9px 12px 1px rgba(0, 0, 0, 0.07),
  0px 5px 6px -3px rgba(0, 0, 0, 0.05);
$shadow-12: 0px 12px 7px 2px rgba(0, 0, 0, 0.07),
  0px 7px 8px -4px rgba(0, 0, 0, 0.05);
$shadow-16: 0px 16px 24px 2px rgba(0, 0, 0, 0.07),
  0px 8px 10px -5px rgba(0, 0, 0, 0.05);
$shadow-24: 0px 24px 38px 3px rgba(0, 0, 0, 0.07),
  0px 11px 15px -7px rgba(0, 0, 0, 0.05);

//Shadow Grey / Blur
.shadow-01-blur-grey {
  box-shadow: $shadow-01;
  backdrop-filter: blur(40px) saturate(2%);
  -webkit-backdrop-filter: blur(40px) saturate(2%);
}

.shadow-02-blur-grey {
  box-shadow: $shadow-02;
  backdrop-filter: blur(40px) saturate(2%);
  -webkit-backdrop-filter: blur(40px) saturate(2%);
}

.shadow-03-blur-grey {
  box-shadow: $shadow-03;
  backdrop-filter: blur(40px) saturate(2%);
  -webkit-backdrop-filter: blur(40px) saturate(2%);
}

.shadow-04-blur-grey {
  box-shadow: $shadow-04;
  backdrop-filter: blur(40px) saturate(2%);
  -webkit-backdrop-filter: blur(40px) saturate(2%);
}

.shadow-06-blur-grey {
  box-shadow: $shadow-06;
  backdrop-filter: blur(40px) saturate(2%);
  -webkit-backdrop-filter: blur(40px) saturate(2%);
}

.shadow-08-blur-grey {
  box-shadow: $shadow-08;
  backdrop-filter: blur(40px) saturate(2%);
  -webkit-backdrop-filter: blur(40px) saturate(2%);
}

.shadow-09-blur-grey {
  box-shadow: $shadow-09;
  backdrop-filter: blur(40px) saturate(2%);
  -webkit-backdrop-filter: blur(40px) saturate(2%);
}

.shadow-12-blur-grey {
  box-shadow: $shadow-12;
  backdrop-filter: blur(40px) saturate(2%);
  -webkit-backdrop-filter: blur(40px) saturate(2%);
}

.shadow-16-blur-grey {
  box-shadow: $shadow-16;
  backdrop-filter: blur(40px) saturate(2%);
  -webkit-backdrop-filter: blur(40px) saturate(2%);
}

.shadow-24-blur-grey {
  box-shadow: $shadow-24;
  backdrop-filter: blur(40px) saturate(2%);
  -webkit-backdrop-filter: blur(40px) saturate(2%);
}

//shadwo/white
$shadow-01-white: 0px 1px 1px 0px rgba(0, 0, 0, 0.07),
  0px 1px 3px 0px rgba(0, 0, 0, 0.05);
$shadow-02-white: 0px 2px 2px 0px rgba(0, 0, 0, 0.07),
  0px 1px 5px 0px rgba(0, 0, 0, 0.05);
$shadow-03: 0px 3px 4px 0px rgba(0, 0, 0, 0.07),
  0px 1px 8px 0px rgba(0, 0, 0, 0.05);
$shadow-04-white: 0px 4px 5px 0px rgba(0, 0, 0, 0.07),
  0px 2px 4px -1px rgba(0, 0, 0, 0.05);
$shadow-06-white: 0px 6px 10px 0px rgba(0, 0, 0, 0.07),
  0px 3px 5px -1px rgba(0, 0, 0, 0.05);
$shadow-08-white: 0px 8px 10px 1px rgba(0, 0, 0, 0.07),
  0px 5px 5px -3px rgba(0, 0, 0, 0.05);
$shadow-09-white: 0px 9px 12px 1px rgba(0, 0, 0, 0.07),
  0px 5px 6px -3px rgba(0, 0, 0, 0.05);
$shadow-12-white: 0px 12px 7px 2px rgba(0, 0, 0, 0.07),
  0px 7px 8px -4px rgba(0, 0, 0, 0.05);
$shadow-16-white: 0px 16px 24px 2px rgba(0, 0, 0, 0.07),
  0px 8px 10px -5px rgba(0, 0, 0, 0.05);
$shadow-24-white: 0px 24px 38px 3px rgba(0, 0, 0, 0.07),
  0px 11px 15px -7px rgba(0, 0, 0, 0.05);

//State / ui-01
$enable-01: #ffffff;
$enable-ghost-01: rgba(255, 255, 255, 0);
$hover-01: #ebebed;
$selected-01: #e1e1e3;
$active-01: #e1e1e3;
$selected-hover-01: #cdcdcf;

//State / ui-02
$enable-02: #f5f5f7;
$enable-ghost-02: rgba(244, 244, 244, 0);
$hover-02: #ebebed;
$selected-02: #e1e1e3;
$active-02: #e1e1e3;
$selected-hover-02: #cdcdcf;

//State / ui-03
$enable-03: #ebebed;
$enable-ghost-03: rgba(235, 235, 235, 0);
$hover-03: #e1e1e3;
$selected-03: #e1e1e3;
$active-03: #e1e1e3;
$selected-hover-03: #ababac;

//State / ghost-danger
$enable-ghost-danger-01: transparent;
$hover-ghost-danger-02: #f3e0d8;
$active-ghost-danger-03: #f3d4c7;

//state/danger
$enabled-danger-01: #f35815;
$hover-danger-02: #cc4a12;
$active-danger-03: #a33c0f;

//State / disabled
$disabled-01: #e1e1e3;
$disabled-02: #cdcdcf;
$disabled-03: #a8a8a8;
$disabled-04: #f5f5f7;
$hover-danger-disabled-02: #cdcdcf;
$text-disabled: #a8a8a8;
$text-disabled-01: #c6c6c6;

//State / other
$error-border-01: #f35815;
$focus-02: #424245;
$focus-03: #ffffff;
$highlight-04: #fff5f6;
$field-disabled-border: #e1e1e3;
$field-disabled: #fff5f6;

//State / primary
$enable-primary-01: #e3192a;
$hover-primary-02: #c71524;
$active-primary-03: #ac131f;

//State / secondary
$enable-secondary-01: #ebebed;
$hover-secondary-02: #e1e1e3;
$active-secondary-03: #cdcdcf;

//State / skeleton
$skeleton-01: #f5f5f7;
$skeleton-02: #ebebed;
$skeleton-03: #e1e1e3;
$skeleton-04: #cdcdcf;

//State / tertiary
$enabled-tertiary-01: #161616;
$hover-tertiary-02: #424245;
$active-tertiary-03: #6f6f6f;

//Support
$info-light-01: rgba(235, 235, 237, 1);
$info-default-02: rgba(225, 225, 227, 1);
$info-dark-03: #cdcdcf;
$success-light-04: #d8ebe6;
$success-default: #008967;
$success-dark-06: #00664d;
$success-60: #2BA15E;
$error-light-07: #f3e0d8;
$error-default-08: #f35815;
$error-dark-09: #cc4a12;
$warning-light-10: #f1e9ca;
$warning-default-11: #faca1e;
$warning-dark-12: #b89415;

$support-error-01: #f35815;
$support-error-02: #d65716;
$support-success-03: #008967;
$support-01: #f35815;
$support-02: #008967;
$support-03: #f1c21b;
$support-04: #e1e1e3;

//Text
$text-01: #161616;
$text-02: #6f6f6f;
$text-03: #ababac;
$text-04: #ffffff;
$text-05: #d54d12;
$text-04-danger: #cc4a12;
$text-07-danger: #cc4a12;

//Text light
$text-light-01: #151519;
$text-light-02: #76767F;

//UI
$ui-01: #ffffff;
$ui-02: #f5f5f7;
$ui-03: #ebebed;
$ui-04: #e1e1e3;
$ui-05: #cdcdcf;
$ui-06: #ababac;
$ui-07: #6f6f6f;
$ui-08: #424245;
$ui-09: #161616;
$ui-transparent-10: transparent;

//UI/blur
$b-ui-01: rgba(255, 255, 255, 0.72);
$b-ui-02: rgba(245, 245, 247, 0.72);
$b-ui-03: rgba(235, 235, 237, 0.72);
$b-ui-04: rgba(225, 225, 227, 0.72);
$b-ui-05: rgba(205, 205, 207, 0.72);
$b-ui-06: rgba(171, 171, 172, 0.72);
$b-ui-07: rgba(111, 111, 111, 0.88);
$b-ui-08: rgba(66, 66, 69, 0.88);
$b-ui-09: rgba(22, 22, 22, 0.88);

//UI/other
$overlay-01: rgba(22, 22, 22, 0.4);

//border color
$grey-color: rgba(57, 57, 57, 0.08);
$light-grey-border: #cdcdcf;
$soft-black-border: rgba(21, 21, 25, .14);

//border smooth radius
$smooth-top-left-radius: 10% 20%;
$smooth-top-left-radius: 10% 20%;
$smooth-top-left-radius: 10% 20%;
$smooth-top-left-radius: 10% 20%;

//01 Brand Color
$brand-color-60: #D3384F;

//02 Neutral
$neutral-20: #eaeaeb;
$neutral-40: #cfcfd3;
$neutral-60: #76767f;
$neutral-95: #151519;

//03 Extended Colors
$han-blue-60: #3666D3;
$han-blue-70: #2F5DC6;

//
// Core: Variables for Dark Mode
//

// Below colors from bootwatch darkly

.text-light-grey{
  $text-light-02: #76767F;
}