.newproviders-view {
  margin-top: 32px;

  .btn-slide {
    display: none;
    @include respond(medium) {
      display: flex;
    }

    &.btn-slide-mobile {
      display: flex;
      @include respond(medium) {
        display: none;
      }
    }
    .icn {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
    }
  }

  .pa-block {
    &__box {
      &--favorites {
        //width: 96.3333%;
        height: 63px;
        background-color: $enable-03;
        border-radius: 12px;
        // padding: 0 24px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        transition: all 0.3s linear;
        overflow: hidden;
        cursor: pointer;

        &.first-box {
          width: 96.3333%;
          height: 63px;

          @include respond(small) {
            height: 95px;
          }
          @include respond(medium) {
            // width: 228px !important;
            height: 106px;
          }
        }
        @include respond(small) {
          height: 95px;
        }
        @include respond(medium) {
          // width: 228px;
          height: 106px;
        }
        &:hover {
          background-color: $hover-03;
        }

        &.active {
          background-color: $active-03;
        }

        &.selected {
          background-color: $active-03;
        }

        &:nth-child(2n) {
          margin-right: 0;
          margin-left: 0;
        }

        img {
          // width: 96.3333%;
          height: 63px;
          object-fit: contain;

          @include respond(medium) {
            //width: 228px;
            height: 106px;
          }
        }
      }
    }
  }
  @include respond(medium) {
    margin-top: 72px;
  }
}

// .slick-track {
//   display: flex !important;

//   .slick-slide:first-child {
//     margin-right: 1rem;
//     @include respond(medium) {
//       margin-right: 0;
//     }
//   }
// }

.slider {
  position: static !important;
}
.slider-trak {
  margin: auto;
  margin-bottom: 24px !important;
  @include respond(medium) {
    margin-bottom: 0 !important;
  }
}
.react-multiple-carousel__arrow {
  bottom: -37px;
  left: unset !important;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  min-width: 24px !important;
  min-height: 24px !important;
  // display: none;
  &::before {
    content: none !important;
  }

  @include respond(medium) {
    top: 36px;
    bottom: auto;
    left: unset !important;
    background-color: transparent !important;
    background-repeat: no-repeat !important;
    min-width: 32px !important;
    min-height: 32px !important;
    display: block;
  }
}
.react-multiple-carousel__arrow--left {
  background-image: url('../../images/chevron--left-active.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  right: auto !important;
  left: calc(40% + 1px) !important;
  &:disabled {
    background-image: url('../../images/chevron--left-dis.svg') !important;
  }

  @include respond(medium) {
    right: 67px !important;
    left: auto !important;
  }

  @include respond(xlarge) {
    // right: calc(((100vw - 92rem) / 2) + 3rem) !important;
  }
}

.react-multiple-carousel__arrow--right {
  background-image: url('../../images/chevron--right-active.svg') !important;
  // right: 0 !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  right: calc(40% + 1px) !important;
  &:disabled {
    background-image: url('../../images/chevron--right-dis.svg') !important;
  }

  @include respond(medium) {
    right: 11px !important;
    left: auto !important;
  }
  @include respond(xlarge) {
    // right: calc(((100vw - 100rem) / 2) + 3rem) !important;
  }
}

.item {
  &:first-child {
    .pa-block__box--favorites {
      margin-left: 0;
    }

    .newprovider-title {
      margin-left: 0;
      margin-right: 13px;
    }
  }

  .pa-block__box--favorites {
    margin: 0 8px;
    @include respond(medium) {
      margin: 0 13px;
    }
  }

  .newprovider-title {
    @include fontSize(12px);
    @include fontFamily('LB-Nu-Semibold');
    @include lineheight(16px);
    @include letterspacing(0.24px);
    text-align: center;
    display: block;
    padding: 12px 11px 0;
  }
}

.bx--skeleton__placeholder {
  width: 96.3333%;
}
