.pa-footer-block {
  max-width: 82rem;
  width: 100%;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  // border-top: 1px solid #ebebeb;
  margin-top: 0;

  @include respond(small) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include respond(medium) {
    margin-top: auto;
    border-top: 1px solid transparent;
    // padding-top: 64px;
    margin-top: auto;
  }
  @include respond(xlarge) {
    margin-top: auto;
    // padding-top: 64px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  &__top {
    // padding-top: 32px;
    flex-direction: column;

    .body-short-02 {
      @include fontSize(12px);
      @include fontFamily('LB-Nu-Medium');
      @include lineheight(16px);
      @include letterspacing(0.24px);
    }

    ul {
      flex-direction: column;

      li {
        padding-right: 0;
        padding-bottom: 16px;

        a {
          color: $link-02;

          &:hover {
            text-decoration: underline;
          }
        }

        &:last-child {
          padding-bottom: 0;
        }
      }

      @include respond(medium) {
        flex-direction: row;
        li {
          padding-right: 32px;
          padding-bottom: 0;
        }
      }
    }

    @include respond(medium) {
      padding-top: 48px;
      flex-direction: row;

      .body-short-02 {
        @include fontSize(14px);
        @include fontFamily('LB-Nu-Medium');
        @include lineheight(18px);
        @include letterspacing(0.16px);
      }
    }
  }

  &__details {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 48px auto 16px;
    // svg {
    //   width: 16px;
    //   height: 16px;
    // }

    & div {
      margin-bottom: 24px;
      padding-left: 0;
    }

    @include respond(medium) {
      justify-content: unset;
      align-items: center;
      margin-top: 0;
      flex-direction: row;
      margin: 0 0 0;

      & div {
        margin-bottom: 0;
        padding-left: 32px;
      }
    }
  }

  &__bottom {
    padding: 1.5rem 0 1rem 0;
    flex-direction: column;
    text-align: center;

    .pa-card-icon {
      span {
        height: 0.875rem;
        display: flex;
        align-items: flex-start;

        @include respond(medium) {
          height: 32px;
          align-items: center;
        }
      }

      .pa-card {
        padding-right: 8px;

        img {
          width: 45px;
          height: 32px;
        }
      }
    }

    .pa-soc-block {
      order: 1;
      margin-bottom: 24px;
      margin-left: auto;
      margin-right: auto;
      .icn {
        font-size: 0;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    & > div {
      order: 2;
    }

    @include respond(medium) {
      flex-direction: row;
      text-align: unset;
      .pa-soc-block {
        order: 2;
        margin-bottom: 0;
        height: 20px;
        margin-left: unset;
        margin-right: unset;
      }

      & > div {
        order: 1;
      }
    }
  }

  &__logo {
    img {
      width: 46px;
      height: 24px;
      margin-right: 24px;
    }
  }

  &__start {
    // border-top: 1px solid #ebebeb;
    // margin-left: -2rem;
    // margin-right: -2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
      li:nth-child(n) {
        order: 1;
      }
      li:nth-child(1) {
        order: 2;
      }
    }
  }
}
.pa-footer-mobile {
  margin-top: auto;
  .pa-footer-block__logo img {
    margin-right: 16px;
  }

  .pa-soc-block {
    .icn {
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .line {
    width: auto;
    margin: 26px -16px;
  }
}
//cookie  policy

.pa-cookie-policy {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 5;
  background-color: #fff;
  border-top: 1px solid #dfdfe1;
  .bx--inline-notification {
    max-width: 82rem !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    @include respond(medium) {
      flex-wrap: wrap;
      padding: 2rem;
    }
  }
  .bx--inline-notification__title {
    @include fontSize(12px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(18px);
    @include letterspacing(0.24px);
    text-align: left;
    color: $text-01;
    font-weight: normal;
    @include respond(medium) {
      @include fontSize(14px);
      @include fontFamily('LB-Nu-Medium');
      @include lineheight(20px);
      @include letterspacing(0.16px);
      text-align: left;
      color: $text-01;
      font-weight: normal;
    }
  }
  .bx--inline-notification__subtitle {
    @include fontSize(12px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(18px);
    @include letterspacing(0.24px);
    text-align: left;
    color: $text-01;
    @include respond(medium) {
      @include fontSize(14px);
      @include fontFamily('LB-Nu-Medium');
      @include lineheight(20px);
      @include letterspacing(0.16px);
      text-align: left;
      color: $text-01;
    }
  }

  .bx--inline-notification__text-wrapper {
    padding: 0 !important;

    display: block !important;
  }
  .bx--inline-notification__details {
    margin: 0 !important;
    max-width: 750px;
    @include respond(medium) {
      max-width: 750px;
    }
  }

  .bx--inline-notification__close-button {
    width: 2.5rem !important;
    height: 2.5rem !important;
    min-width: 2.5rem !important;
    position: static !important;
    margin-left: 8px;
    border-radius: 6px;
    transition: 0.3s;

    svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }

    &:hover {
      background-color: #eaeaeb;
    }

    &:active {
      background-color: #eaeaeb;
    }
    @include respond(medium) {
      margin-left: 20px;
    }
  }
  .bx--inline-notification__close-icon {
    fill: $text-01;
  }

  .bx--inline-notification:not(.bx--inline-notification--low-contrast) a {
    color: $text-01 !important;
    text-decoration: underline !important;
  }
}
