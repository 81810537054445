.inside-container {
  padding-bottom: 64px;
  .bx--grid {
    max-width: 82rem !important;
  }

  .providers-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include respond(medium) {
      flex-direction: row;
    }
  }

  .all-categories-providers {
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    @include respond(medium) {
      margin-left: 44px;
      margin-right: -12px;
    }
  }

  .all-search-row {
    margin: 0 0;
    padding-top: 32px;
    .providers {
      width: calc(100% / 2 - 8px);
      margin: 0 8px 24px 0;
      cursor: pointer;
      text-align: left;

      &:nth-child(2n) {
        margin-right: 0;
        margin-left: 8px;
      }

      &:nth-child(1) {
        margin-left: 0;
      }
      p {
        margin-bottom: 16px;
      }
      @include respond(medium) {
        width: calc(100% / 4 - 32px) !important;
        margin: 16px;
        text-align: unset;

        &:nth-child(2n) {
          margin-right: 16px;
          margin-left: 16px;
        }
        &:nth-child(1) {
          margin-left: 16px;
        }
      }
    }

    @include respond(medium) {
      margin: 0 -12px;
    }
  }

  .all-categories {
    &__content {
      h1 {
        color: $text-01;
      }

      p {
        color: $text-02;
      }
    }

    &__row {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .pa-block {
        &__favorites-in {
          height: auto;
          background-color: $enable-03;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s linear;
          overflow: hidden;

          &:hover {
           // background-color: $hover-03;
          }

          &.active {
            background-color: $active-03;
          }

          &.selected {
            background-color: $active-03;
          }

          img {
            width: 100%;
            height: auto;
            // mix-blend-mode: darken;
          }

          @include respond(small) {
            height: auto;
            img {
              @include respond(small) {
                width: 100%;
                height: auto;
              }
            }
          }
          @include respond(medium) {
            height: auto;
            img {
              @include respond(medium) {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }

    &__box {
      cursor: pointer;
      width: calc(100% / 2 - 32px);
      margin: 0 16px 16px;
      text-align: center;

      &:hover {
        h2 {
          text-decoration: underline;
        }
      }

      h2 {
        color: $link-02;
        padding: 8px 0 4px 0;
      }

      p {
        color: $text-02;
      }

      &.providers {
        width: calc(100% / 2 - 8px);
        margin: 0 8px 24px 0;
        cursor: pointer;
        text-align: left;

        &:nth-child(2n) {
          margin-right: 0;
          margin-left: 8px;
        }

        &:nth-child(1) {
          margin-left: 0;
        }
        p {
          margin-bottom: 0;
          color: $text-02;
        }
        @include respond(medium) {
          width: calc(100% / 3 - 32px);
          margin: 0 16px 32px 16px;
          text-align: unset;

          &:nth-child(2n) {
            margin-right: 16px;
            margin-left: 16px;
          }
          &:nth-child(1) {
            margin-left: 16px;
          }

          p {
            margin-bottom: 16px;
          }
        }
        @include respond(large) {
          width: calc(100% / 3 - 32px);
          margin: 0 16px 32px 16px;
          text-align: unset;

          &:nth-child(2n) {
            margin-right: 16px;
            margin-left: 16px;
          }
          &:nth-child(1) {
            margin-left: 16px;
          }
          p {
            margin-bottom: 16px;
          }
        }

        &:hover {
          h2 {
            text-decoration: underline;
          }
        }

        .body-short-02 {
          @include fontSize(12px);
          @include fontFamily('LB-Nu-Medium');
          @include lineheight(16px);
          @include letterspacing(0.24px);
          @include respond(medium) {
            @include fontSize(14px);
            @include fontFamily('LB-Nu-Medium');
            @include lineheight(18px);
            @include letterspacing(0.16px);
          }
        }
      }

      @include respond(medium) {
        width: calc(100% / 4 - 32px);
        text-align: unset;
        margin-bottom: 32px;

        h2 {
          color: $link-02;
          padding: 12px 0 4px 0;
        }
      }
      @include respond(large) {
        width: calc(100% / 4 - 32px);
      }
    }
  }
}
