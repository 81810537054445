.bx--tooltip {
  background-color: $ui-09;
  box-shadow: none;
  border-radius: 6px;

  .bx--tooltip__caret {
    border-radius: 2px;
    transform: rotate(45deg);
    background: $ui-09;
    width: 12px;
    height: 17px;
    border: none;
  }
}
.bx--tooltip__trigger.bx--tooltip--top:focus svg {
  outline: none !important;
  fill: none !important;
}
.bx--assistive-text {
  background-color: $ui-09 !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  padding: 3px 16px !important;
  font-size: 14px !important;
  @include fontFamily('LB-Nu-Medium');
  color: $icon-05;
  max-width: 12.5rem !important;

  @include respond(medium) {
    max-width: 18rem !important;
  }
}

.bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start::after,
.bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start
  .bx--assistive-text,
.bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start
  + .bx--assistive-text {
  left: -30px;
}

.bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end::after,
.bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end
  .bx--assistive-text,
.bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end
  + .bx--assistive-text {
  right: -30px;
}

.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end::after,
.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end
  .bx--assistive-text,
.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end
  + .bx--assistive-text {
  right: -30px;
}

.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start::after,
.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start
  .bx--assistive-text,
.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start
  + .bx--assistive-text {
  left: -30px;
}

.bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start::before,
.bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end::before,
.bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center::before {
  background-image: url('../../images/icons/triangle-top.svg');
  width: 16px !important;
  height: 6px !important;
  border: none;
  background-repeat: no-repeat;
}

.bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start::before {
  top: -0.5rem;
}

.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start::before,
.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end::before,
.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center::before {
  background-image: url('../../images/icons/triangle-bottom.svg');
  width: 16px !important;
  height: 6px !important;
  border: none;
  background-repeat: no-repeat;
}

.bx--tooltip__trigger.bx--tooltip--left::before {
  background-image: url('../../images/icons/triangle-left.svg');
  width: 5px !important;
  height: 12px !important;
  border: none;
  background-repeat: no-repeat;
}

.bx--tooltip__trigger.bx--tooltip--right::before {
  background-image: url('../../images/icons/triangle-right.svg');
  width: 5px !important;
  height: 12px !important;
  border: none;
  background-repeat: no-repeat;
}

.bx--tooltip--top {
  .icn {
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
}

.pa-tooltip-parent {
  display: inline-block;
  max-width: 100%;
  height: 100%;
}

.bx--tooltip[data-floating-menu-direction='top'] .bx--tooltip__caret {
  transform: rotate(45deg);
}

.bx--tooltip.bx--tooltip--top.bx--tooltip--align-start .bx--tooltip__caret,
.bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-start .bx--tooltip__caret {
  margin-left: 30px;
}

.mob-tooltip {
  //display: block;
  li:last-child {
    padding-bottom: 0;
  }

  @include respond(medium) {
    //   display: none;
  }
}
.bx--tooltip__label {
  display: initial;
  display: block;

  @include respond(medium) {
    display: none;
  }
}

.bx--tooltip__trigger svg {
  fill: #6f6f6f;
  width: 1rem;
  height: 1rem;
}

.bx--tooltip__label .bx--tooltip__trigger {
  margin-left: 0.25rem;
}

.bx--tooltip__trigger:not(.bx--btn--icon-only):focus {
  outline: 1px solid transparent;
  fill: #6f6f6f;
}

.bx--tooltip__trigger:hover svg,
.bx--tooltip__trigger:focus svg {
  fill: #6f6f6f;
}

.bx--tooltip.bx--tooltip--top.bx--tooltip--align-start,
.bx--tooltip.bx--tooltip--bottom.bx--tooltip--align-start {
  transform: translate(calc(50% - 37px), 0);
}

.web-tooltip {
  display: none !important;
  @include respond(medium) {
    display: inline-flex !important;
  }
}
