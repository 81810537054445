.pa-header {
  min-height: 322px;
  height: 100%;
  position: relative;

  .pa-header-image-container {
    display: block;
    overflow: hidden;
    position: absolute;
    top: -167px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    .img-container {
      position: absolute;
      top: 34%;
      left: -35vw;
      right: -35vw;
      bottom: 0;
      overflow: hidden;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      // &:before {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   right: 0;
      //   bottom: 0;
      //   background-image: linear-gradient(180deg, #000 0%, #0000 100%);
      //   opacity: 0.68;
      //   z-index: 1;
      // }

      .bg-image {
        background-image: url('../../images/header-img.png');
        position: absolute;
        top: 0;
        // left: 20vw;
        right: 35vw;
        // bottom: 0;
        // background-position: center 2px;
        background-size: cover;
        background-position: bottom;
        left: 35vw;
        bottom: -34px;

        @media screen and (max-width: 1920px) {
          background-image: url('../../images/header-img.png');
          background-position: bottom;
          left: 35vw;
          bottom: 4px;
        }
        @media screen and (max-width: 1600px) {
          background-image: url('../../images/header-img.png');
          background-position: bottom;
          left: 35vw;
          bottom: 4px;
        }

        @media screen and (max-width: 1400px) {
          background-image: url('../../images/header-img.png');
          background-position: bottom;
          left: 35vw;
          bottom: -4px;
        }

        // @media screen and (max-width: 1367px) {
        //   background-image: url('../../images/header-img.png');
        //   background-position: 100%;
        //   left: 35vw;
        //   bottom: -4px;
        // }
        @media screen and (max-width: 1056px) {
          background-image: url('../../images/header-img.png');
          background-position: 50%;
          left: 32vw;
          bottom: 5px;
        }

        @media screen and (max-width: 672px) {
          background-image: url('../../images/header-img.png');
          background-position: 50%;
          top: -11px;
          left: 30vw;
          right: -98vw;
        }
        @media screen and (max-width: 500px) {
          background-image: url('../../images/header-img-mobile.png');
          top: -111px;
          left: 35vw;
          right: 35vw;
          bottom: 0;
          background-position: center 2px;
          background-size: cover;
        }
        @media screen and (max-width: 480px) {
          background-image: url('../../images/header-img-mobile.png');
          top: -97px;
          left: 20vw;
          right: 35vw;
          bottom: 0;
          background-position: center 2px;
          background-size: cover;
        }
        @media screen and (max-width: 428px) {
          background-image: url('../../images/header-img-mobile.png');
          top: -75px;
          left: 35vw;
          right: 32vw;
          bottom: 0;
          background-position: center 2px;
          background-size: cover;
        }
        @media screen and (max-width: 415px) {
          background-image: url('../../images/header-img-mobile.png');
          top: -86px;
          left: 35vw;
          right: 24vw;
          bottom: 0;
          background-position: center 2px;
          background-size: cover;
        }
        @media screen and (max-width: 391px) {
          background-image: url('../../images/header-img-mobile.png');
          top: -75px;
          left: 35vw;
          right: 24vw;
          bottom: 0;
          background-position: center 2px;
          background-size: cover;
        }
        @media screen and (max-width: 376px) {
          background-image: url('../../images/header-img-mobile.png');
          top: -44px;
          left: 35vw;
          right: 24vw;
          bottom: 0;
          background-position: center 2px;
          background-size: cover;
        }
        @media screen and (max-width: 321px) {
          background-image: url('../../images/header-img-mobile.png');
          top: -2px;
          left: 35vw;
          right: 24vw;
          bottom: 0;
          background-position: center 2px;
          background-size: cover;
        }
        // @include respond(medium) {
        //   background-image: url('../../images/header-bg.png');
        //   background-position: bottom;
        //   left: 35vw;
        //   bottom: -34px;
        // }
      }
      @include respond(small) {
        border-bottom-left-radius: 36%;
        border-bottom-right-radius: 36%;
      }
      @include respond(medium) {
        position: absolute;
        top: 26%;
        left: -35vw;
        right: -35vw;
        bottom: 0;
        overflow: hidden;
        border-bottom-left-radius: 45%;
        border-bottom-right-radius: 45%;
      }
    }

    @include respond(medium) {
      display: block;
      overflow: hidden;
      position: absolute;
      top: -125px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  .pa-header-top {
    display: flex;
    align-items: center;
    padding: 1.375rem 1rem 0.5rem 1rem;
    max-width: 82rem;
    margin-left: auto;
    margin-right: auto;

    @include respond(small) {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 1.375rem;
      padding-bottom: 0.3125rem;
    }

    @include respond(medium) {
      height: 80px;
    }

    @include respond(xlarge) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      padding-top: 1.375rem;
      padding-bottom: 0.3125rem;
    }

    &__logo {
      height: 100%;

      a {
        display: flex;
        height: 100%;
        align-items: center;
        border: solid 2px transparent;
        transition: background-color 110ms, border-color 110ms, color 110ms;
        outline: none;
      }
      img {
        width: 86px;
        height: 40px;
      }
      @include respond(medium) {
        img {
          width: 112px;
          height: 53px;
        }
      }
    }

    &__global {
      display: flex;
      align-items: center;
      flex: 1 1;
      justify-content: flex-end;
      height: 100%;
      .pa-old-vers {
        position: relative;
        margin-right: 26px;
        cursor: pointer;
        &::after {
          content: '';
          width: 1px;
          height: 1rem;
          background-color: $background-white;
          opacity: 0.32;
          display: inline-block;
          border-radius: 0.5px;
          margin-left: 24px;
          position: absolute;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__phone {
      @include fontSize(14px);
      color: $text-04;
      display: none;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
      }

      @include respond(medium) {
        display: flex;
      }
    }

    &__faq {
      color: $text-04;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__basket {
      color: $text-04;
      position: relative;
      padding-right: 6px;

      svg {
        width: 24px;
        height: 24px;
        @include respond(medium) {
          width: 24px;
          height: 24px;
        }
      }
      @include respond(medium) {
        padding-right: 0;
      }
    }

    &__profile {
      color: $text-04;

      &::before {
        content: '';
        width: 1px;
        height: 16px;
        background-color: $background-white;
        opacity: 0.32;
        display: inline-block;
        border-radius: 0.5px;
      }

      span {
        display: flex;
        margin-top: -18px;
      }
    }

    &__profile--mobile {
      svg {
        width: 24px;
        height: 24px;
        color: #fff;
      }
    }
  }

  .pa-search {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 1;
    z-index: 1051;
    margin-top: 16px;
    max-width: 99rem;
    margin-left: auto;
    margin-right: auto;
    padding-right: 1rem;
    padding-left: 1rem;
    position: absolute;
    //bottom: 0;
    width: 100%;

    .productive-heading-04 {
      @include fontSize(20px);
      @include fontFamily('LB-Nu-Semibold');
      @include lineheight(28px);
      @include letterspacing(0.16px);
      padding-bottom: 20px;
      @include respond(medium) {
        @include fontSize(28px);
        @include fontFamily('LB-Nu-Semibold');
        @include lineheight(36px);
        @include letterspacing(0);
        padding-bottom: 32px;
      }
    }

    h3 {
      color: $inverse-text-01;
    }

    .pa-search-out {
      position: relative;
      width: 100%;
      z-index: 1055;
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none;
      .bx--search-close {
        width: 2.75rem;
        height: 2.5rem;
        color: $icon-02;
        background-color: transparent;
        border: none;
        top: 4px;
        right: 4px;
        transition: none;
        &::before {
          content: none;
        }
        &:hover {
          outline: none;
          outline-offset: 0;
          border: none;
          background-color: $hover-02;
          border-radius: 6px;
          box-shadow: none;
          border: none;
        }
        &:active {
          outline: none;
          outline-offset: 0;
          background-color: $hover-02;
          border-radius: 6px;
          box-shadow: none;
          border: none;
        }
        &:focus {
          outline: none;
          outline-offset: 0;
          background-color: transparent;
        }
        &:disabled {
          outline: none;
          outline-offset: 0;
          background-color: transparent;
          border-radius: 6px;
          box-shadow: none;
          border: none;

          svg {
            opacity: 0.2;
          }
        }
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }

        @include respond(medium) {
          width: 3rem;
          height: 3rem;
        }
      }

      .bx--search-magnifier {
        .bx--search-magnifier-icon {
          left: 0.5rem;
          width: 1.25rem;
          height: 1.25rem;
          @include respond(medium) {
            left: 1.5rem;
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
      .bx--search-input {
        width: 100%;
        //  max-width: 740px;
        height: 48px;
        padding: 0 50px 0 42px;
        box-shadow: $shadow-04;
        border: 1px solid $grey-color;
        border-radius: 10px;
        outline: none;
        background-color: $background-white;
        &::placeholder {
          @include fontSize(14px);
          @include fontFamily('LB-Nu-Medium');
          @include lineheight(18px);
          @include letterspacing(0.16px);
          color: $text-03;
        }

        &::-webkit-input-placeholder {
          @include fontSize(14px);
          @include fontFamily('LB-Nu-Medium');
          @include lineheight(18px);
          @include letterspacing(0.16px);
          color: $text-03;
        }

        &::-moz-placeholder {
          @include fontSize(14px);
          @include fontFamily('LB-Nu-Medium');
          @include lineheight(18px);
          @include letterspacing(0.16px);
          color: $text-03;
        }

        &:-ms-input-placeholder {
          @include fontSize(14px);
          @include fontFamily('LB-Nu-Medium');
          @include lineheight(18px);
          @include letterspacing(0.16px);
          color: $text-03;
        }

        &:-moz-placeholder {
          @include fontSize(14px);
          @include fontFamily('LB-Nu-Medium');
          @include lineheight(18px);
          @include letterspacing(0.16px);
          color: $text-03;
        }

        &:focus {
          outline: none;
        }

        &:active {
          outline: none;
        }

        &:disabled {
          background-color: $field-disabled-02;
          cursor: not-allowed;
          border: 1px solid $field-disabled-border;
          outline: none;

          &::placeholder {
            color: $text-disabled-01;
          }
        }

        &.pa-search-input--md {
          height: 40px;
        }

        //   @include respond(medium) {
        //     padding: 6px 25px 0 64px;
        //   }
        // }

        // .icn {
        //   position: absolute;
        //   top: 27px;
        //   left: 105px;
        //   transform: translateY(-50%);

        //   svg {
        //     width: 20px;
        //     height: 20px;
        //     color: $text-01;
        //   }

        //   @include respond(medium) {
        //     position: absolute;
        //     top: 31px;
        //     left: 24px;
        //     transform: translateY(-50%);
        //     svg {
        //       width: 24px;
        //       height: 24px;
        //       color: $text-01;
        //     }
        //   }
        // }
        // .icn-close-m {
        //   position: absolute;
        //   top: 53%;
        //   // right: 14px;
        //   left: 85.33%;
        //   transform: translateY(-50%);
        //   cursor: pointer;

        //   svg {
        //     width: 24px;
        //     height: 24px;
        //     color: $icon-02;
        //   }

        //   @include respond(medium) {
        //     left: 94%;
        //   }

        @include respond(medium) {
          height: 56px;
          padding: 0 50px 0 62px;
        }
      }

      @include respond(medium) {
        width: 740px;
      }
      &:focus-visible {
        outline: none !important;
      }
      &:focus {
        outline: none !important;
      }
    }

    @include respond(small) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @include respond(medium) {
      position: static;
      margin-top: 64px;
      bottom: 0;
      width: 100%;
      padding-right: 1rem;
      padding-left: 1rem;
      .productive-heading-04 {
        @include fontSize(28px);
        @include fontFamily('LB-Nu-Semibold');
        @include lineheight(36px);
        @include letterspacing(0);
      }

      .pa-search-out {
        .pa-search-input {
          height: 56px;
        }
      }
    }
    @include respond(xlarge) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }

  @include respond(medium) {
    min-height: 356px;
    height: 100%;
  }
}

// Inside layout header
.pa-header-inside {
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: fixed;
  width: 100%;
  z-index: 1050;

  .pa-header-top {
    display: flex;
    align-items: center;
    padding: 1.375rem 1rem 1.375rem 1rem;
    max-width: 82rem;
    margin-left: auto;
    margin-right: auto;

    @include respond(small) {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    @include respond(xlarge) {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &__logo {
      height: 100%;
      flex: 1 1;

      a {
        display: flex;
        height: 100%;
        align-items: center;
        border: solid 2px transparent;
        transition: background-color 110ms, border-color 110ms, color 110ms;
        outline: none;
      }
      img {
        height: 26px;
      }
      @include respond(medium) {
        img {
          width: 111px;
          height: 35px;
        }
      }
    }

    &__global {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
    }

    &__phone {
      @include fontSize(14px);
      color: $icon-01;
      display: none;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
      }

      @include respond(medium) {
        display: flex !important;
      }
    }

    &__faq {
      color: $icon-01;
      position: relative;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    &__search {
      color: #000;
      position: relative;

      svg {
        width: 24px;
        height: 24px;
        @include respond(medium) {
          width: 20px;
          height: 20px;
        }
      }
    }
    &__basket {
      color: $text-01;
      position: relative;
      padding-right: 6px;
      svg {
        width: 24px;
        height: 24px;

        @include respond(medium) {
          width: 24px;
          height: 24px;
        }
      }

      @include respond(medium) {
        padding-right: 6px;
      }
      .badge {
        @include respond(medium) {
          right: 8px;
        }
      }
    }

    .active {
      &::after {
        content: '';
        width: 19px;
        height: 2px;
        background-color: $icon-06;
        position: absolute;
        bottom: -2px;
        left: 2px;

        @include respond(medium) {
          width: 19px;
        }
      }
    }

    &__profile {
      color: $text-01;

      &::before {
        content: '';
        width: 1px;
        height: 16px;
        background-color: $background-black;
        opacity: 0.32;
        display: inline-block;
        border-radius: 0.5px;
      }

      span {
        margin-top: -21px;
      }
    }

    &__profile--mobile {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__top {
    width: 100%;
    height: 72px;
    background-color: $ui-02;
    border-bottom: 1px solid $ui-04;

    @include respond(medium) {
      height: 80px;
    }
  }

  &__breadcrumb {
    min-height: 48px;
    background-color: $ui-03;
    backdrop-filter: blur(40px);
    padding: 15px 0;
    margin-top: 80px;

    .container-breadcrumb {
      max-width: 82rem;
      margin-left: auto;
      margin-right: auto;
      padding-right: 1rem;
      padding-left: 1rem;

      @include respond(small) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @include respond(xlarge) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }

  .pa-search-out {
    position: relative;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
    z-index: 1055;
    //max-width: 512px;
    &:focus-visible {
      outline: none !important;
    }
    &:focus {
      outline: none !important;
    }
    .pa-search-focused {
      .bx--search-input {
        width: 740px;
      }
    }
    .bx--search-input {
      width: 512px;
      height: 48px;
      @include placeholder($text-01);
      padding: 0 25px 0 44px;
      border: 1px solid $ui-05;
      border-radius: 6px;
      background-color: $background-white;
      // -webkit-transition: width 1s ease-in-out;
      // -moz-transition: width 1s ease-in-out;
      // -o-transition: width 1s ease-in-out;
      // transition: width 1s ease-in-out;
      // -webkit-transition: width 0.5s ease-in-out;
      // transition: width 0.5s ease-in-out;

      &:focus {
        border: 2px solid $focus-02 !important;
        box-shadow: none !important;
        outline: none;
        padding: 0 25px 0 43px;
      }

      &:active {
        border: 2px solid $focus-02;
        box-shadow: none !important;
        outline: none;
      }

      &:disabled {
        background-color: $field-disabled-02;
        cursor: not-allowed;
        outline: 2px solid $field-disabled-border;
        outline: none;
        -moz-outline-radius: 6px;

        &::placeholder {
          color: $text-disabled-01;
        }
      }

      &::placeholder {
        @include fontSize(14px);
        @include fontFamily('LB-Nu-Medium');
        @include lineheight(18px);
        @include letterspacing(0.16px);
        color: $text-03;
      }
      &.pa-search-input--md {
        height: 40px;
      }
    }

    .bx--search-magnifier {
      top: 50%;
      left: 18px;
      transform: translateY(-50%);

      .bx--search-magnifier-icon {
        width: 20px;
        height: 20px;
        color: $icon-02;
        left: 1rem;
      }
    }

    .bx--search-close {
      position: absolute;
      top: 50%;
      right: 4px;
      transform: translateY(-50%);
      transition: none;
      &::before {
        content: none;
      }
      &:hover {
        outline: none;
        outline-offset: 0;
        background-color: $hover-02;
        border-radius: 6px;
        box-shadow: none;
        border: none;
      }
      &:active {
        outline: none;
        outline-offset: 0;
        background-color: $hover-02;
        border-radius: 6px;
        box-shadow: none;
        border: none;
      }
      &:focus {
        outline: none;
        outline-offset: 0;
        background-color: transparent;
      }

      &:disabled {
        outline: none;
        outline-offset: 0;
        background-color: transparent;
        border-radius: 6px;
        box-shadow: none;
        border: none;

        svg {
          opacity: 0.2;
        }
      }
      svg {
        width: 24px;
        height: 24px;
        color: $icon-02;
      }
    }

    .icn-close-focused {
      position: absolute;
      top: 53%;
      // right: 14px;
      left: 65.222%;
      transform: translateY(-50%);
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
        color: $icon-02;
      }
    }
  }

  &__search {
    background-color: $ui-03;
    width: 100%;
    padding: 12px 8px;
    animation: fadeIn ease 0.5s;
    -webkit-animation: fadeIn ease 0.5s;
    -moz-animation: fadeIn ease 0.5s;
    -o-animation: fadeIn ease 0.5s;
    -ms-animation: fadeIn ease 0.5s;
    .pa-search-out {
      .icn {
        top: 57% !important;
        left: 16px !important;
      }
    }

    .pa-search-out--mobile {
      display: block !important;
      margin-left: 0 !important;
      z-index: 1055;

      .bx--search-input {
        width: 100%;
      }

      .pa-search-focused {
        .bx--search-input {
          width: 100%;
        }
      }

      .icn-close {
        left: 97% !important;
      }

      .icn-close-focused {
        left: 97% !important;
      }
    }
    .pa-search-input {
      width: 100% !important;
      height: 40px !important;
      border-radius: 6px !important;
      padding: 0 10px 0 48px !important;
    }
  }
}

.pa-back-heading {
  background-color: $b-ui-01;
  backdrop-filter: blur(40px);
  padding-bottom: 8px;
  // -webkit-transition: 0.3s all ease;
  // transition: 0.3s all ease;

  &.scroll {
    width: 100%;
    position: fixed;
    top: 0;
    // position: sticky;
    // top: 0;
    z-index: 1060;
    box-shadow: $shadow-01;
    padding-bottom: 0;
    .pa-back-heading-in {
      padding-top: 24px;
    }

    @include respond(medium) {
      //padding-bottom: 8px;
    }
  }
  &.noti-top {
    margin-top: 64px;
    @include respond(medium) {
      margin-top: 0;
    }
  }
  .pa-back-heading-in {
    color: $text-01;
    display: flex;
    align-items: center;
    max-width: 82rem;
    margin-left: auto;
    margin-right: auto;
    padding-right: 1rem;
    padding-left: 1rem;
    // padding-top: 16px;
    padding-bottom: 16px;
    padding-top: 88px;

    &.productive-heading-03 {
      @include fontSize(14px);
      @include fontFamily('LB-Nu-Bold');
      @include lineheight(18px);
      @include letterspacing(0.16px);
    }

    .bx--skeleton__text {
      width: 6.25rem !important;
      margin-left: 24px;
      margin-top: 10px;
    }

    h1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -ms-text-overflow: ellipsis;
      padding-left: 16px;
      font-weight: normal;
      @include respond(medium) {
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
        -ms-text-overflow: unset;
        padding-left: 24px;
      }
    }

    @include respond(small) {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 88px;
    }

    @include respond(medium) {
      padding-top: 24px;
      padding-bottom: 24px;
      &.productive-heading-03 {
        @include fontSize(20px);
        @include fontFamily('LB-Nu-Semibold');
        @include lineheight(28px);
        @include letterspacing(0.16px);
      }
    }

    @include respond(xlarge) {
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .btn {
      display: flex;

      .icn {
        color: $icon-01;
      }
    }
  }

  @include respond(medium) {
    padding-bottom: 0;
  }
}

.element-visible {
  display: block;
}
.element-hidden {
  display: none;
}

.bx--search--lg .bx--search-close {
  width: 2.75rem;
  height: 2.5rem;
}

//mastercard popup
.pa-mastercard {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  background-color: #121216;
  .bx--inline-notification {
    max-width: 82rem !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 1rem 1rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    @include respond(medium) {
      flex-wrap: wrap;
      padding: 1rem 2rem;
    }
  }
  .bx--inline-notification__title {
    margin: 0 !important;
    line-height: 0 !important;
  }
  .bx--inline-notification__subtitle {
    @include fontSize(12px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(18px);
    @include letterspacing(0.24px);
    text-align: center;

    @include respond(medium) {
      @include fontSize(14px);
      @include fontFamily('LB-Nu-Medium');
      @include lineheight(20px);
      @include letterspacing(0.16px);
      text-align: left;
    }
  }

  .bx--inline-notification__text-wrapper {
    padding: 0 !important;
    align-items: center;
    margin: 0 auto !important;
    flex-wrap: nowrap !important;
  }
  .bx--inline-notification__details {
    margin: 0 !important;
  }
  .pa-mastercard-cont {
    max-width: 82rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .icn-mastercard {
    width: 45px;
    height: 32px;
    margin-right: 9px;
    @include respond(medium) {
      margin-right: 1rem;
    }
  }
  .bx--inline-notification__close-button {
    width: 1.5rem !important;
    height: 1.5rem !important;
    min-width: 1.5rem !important;
    position: static !important;
    margin-left: 20px;
  }
}
