*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
body {
  background-color: $background-white;
  font-family: 'LB-Nu-Medium';
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
iframe {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

svg {
  width: 100%;

  height: 100%;
  pointer-events: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

@font-face {
  font-family: 'LB-Nu-Regular';
  src: url('../../fonts/LB-Nu-Regular.eot');
  src: url('../../fonts/LB-Nu-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/LB-Nu-Regular.woff2') format('woff2'),
    url('../../fonts/LB-Nu-Regular.woff') format('woff'),
    url('../../fonts/LB-Nu-Regular.ttf') format('truetype'),
    url('../../fonts/LB-Nu-Regular.svg#LB-Nu-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
  -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
}

@font-face {
  font-family: 'LB-Nu-Medium';
  src: url('../../fonts/LB-Nu-Medium.eot');
  src: url('../../fonts/LB-Nu-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/LB-Nu-Medium.woff2') format('woff2'),
    url('../../fonts/LB-Nu-Medium.woff') format('woff'),
    url('../../fonts/LB-Nu-Medium.ttf') format('truetype'),
    url('../../fonts/LB-Nu-Medium.svg#LB-Nu-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
  -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
}

@font-face {
  font-family: 'LB-Nu-Bold';
  src: url('../../fonts/LB-Nu-Bold.eot');
  src: url('../../fonts/LB-Nu-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/LB-Nu-Bold.woff2') format('woff2'),
    url('../../fonts/LB-Nu-Bold.woff') format('woff'),
    url('../../fonts/LB-Nu-Bold.ttf') format('truetype'),
    url('../../fonts/LB-Nu-Bold.svg#LB-Nu-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
  -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
}

@font-face {
  font-family: 'LB-Nu-SemiBold';
  src: url('../../fonts/LB-Nu-SemiBold.eot');
  src: url('../../fonts/LB-Nu-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/LB-Nu-SemiBold.woff2') format('woff2'),
    url('../../fonts/LB-Nu-SemiBold.woff') format('woff'),
    url('../../fonts/LB-Nu-SemiBold.ttf') format('truetype'),
    url('../../fonts/LB-Nu-SemiBold.svg#LB-Nu-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
  -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
}

@font-face {
  font-family: 'LB-Nu-ExtraBold';
  src: url('../../fonts/LB-Nu-ExtraBold.eot');
  src: url('../../fonts/LB-Nu-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/LB-Nu-ExtraBold.woff2') format('woff2'),
    url('../../fonts/LB-Nu-ExtraBold.woff') format('woff'),
    url('../../fonts/LB-Nu-ExtraBold.ttf') format('truetype'),
    url('../../fonts/LB-Nu-ExtraBold.svg#LB-Nu-ExtraBold') format('svg');
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
  -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
}

@font-face {
  font-family: 'LB-Mt-Bold';
  src: url('../../fonts/LB-Mt-Bold.eot');
  src: url('../../fonts/LB-Mt-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/LB-Mt-Bold.woff2') format('woff2'),
    url('../../fonts/LB-Mt-Bold.woff') format('woff'),
    url('../../fonts/LB-Mt-Bold.ttf') format('truetype'),
    url('../../fonts/LB-Mt-Bold.svg#LB-Mt-Bold ') format('svg');
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
  -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
}

@font-face {
  font-family: 'LB-Mt-Regular';
  src: url('../../fonts/LB-Mt-Regular.eot');
  src: url('../../fonts/LB-Mt-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/LB-Mt-Regular.woff2') format('woff2'),
    url('../../fonts/LB-Mt-Regular.woff') format('woff'),
    url('../../fonts/LB-Mt-Regular.ttf') format('truetype'),
    url('../../fonts/LB-Mt-Regular.svg#LB-Mt-Regular ') format('svg');
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
  -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
}
