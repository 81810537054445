.balance-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .btn-block-mobile {
    & > div {
      display: block;
    }
    .btn-secondary {
      display: block;
    }
  }
  .btn-block-web {
    & > div {
      display: none;
    }
    .btn-secondary {
      display: none;
    }
  }
  @include respond(medium) {
    flex-direction: row;
    //padding-bottom: 130px;

    .btn-block-mobile {
      & > div {
        display: none;
      }
      .btn-secondary {
        display: none;
      }
    }
    .btn-block-web {
      & > div {
        display: block;
      }
      .btn-secondary {
        display: block;
      }
    }
  }
}

.pa-balance-left {
  max-width: none;
  width: 100%;
  padding-bottom: 0;

  .pr-55 {
    padding-right: 0;
  }
  p {
    margin-bottom: 0;
  }
  @include respond(medium) {
    max-width: 424px;
    padding-bottom: 48px;

    .pr-55 {
      padding-right: 55px;
    }

    .bx--dropdown {
      width: 368px;
    }
  }
}

.pa-balance-center {
  padding: 0 0;
  padding-top: 8px;
  border-left: none;

  .pa-details-group {
    &:not(:last-child) {
      margin-bottom: 32px;
    }

    &__label {
      color: $text-02;
      margin-bottom: 0.5rem;

      svg {
        color: $icon-02;
        width: 1rem;
        height: 1rem;
      }
    }

    &__balance {
      color: $text-01;
    }
  }
  .providers {
    &__details-box {
      width: auto;
      @include respond(medium) {
        width: 424px;
      }

      .productive-heading-01 {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @include respond(medium) {
    padding: 0 56px;
    border-left: 1px solid $ui-03;
    padding-top: 0;
  }
}

.pa-balance-right {
  //width: 288px;
  margin-left: 0;
  img {
    width: 256px;
    border-radius: 10px;
  }
  .providers {
    &__box {
      margin-top: 32px;
      width: 136px;
      height: 63px;

      img {
        width: 136px;
        height: 63px;
      }

      @include respond(medium) {
        margin-top: 0;
        width: 288px;
        height: 134px;

        img {
          width: 288px;
          height: 134px;
        }
      }
    }

    &__details-box {
      a {
        color: $link-01;

        &:hover {
          text-decoration: underline;
        }
      }

      .productive-heading-03 {
        margin-top: 16px;
        @include respond(medium) {
          margin-top: 12px;
        }
      }
    }
  }

  @include respond(medium) {
    margin-left: auto;
  }
}

.btn-block {
  padding-top: 0;
  .icn-arrow {
    transform: rotate(180deg);
  }

  @include respond(medium) {
    padding-top: 8px;
  }
}

.pa-balance {
  width: 100%;
  padding-bottom: 48px;
  margin-top: 72px; //made mob knobs visible, below header

  @include respond(medium) {
    padding-bottom: 48px;
    margin-top: 0;
    .pr-56 {
      padding-right: 56px;
    }
  }

  .bx--dropdown {
    @include respond(medium) {
      width: 318px;
    }
  }

  .pa-text-input {
    @include respond(medium) {
      width: 318px;

      &__field-wrapper {
        width: 318px;
      }
    }
  }

  .pr-56 {
    padding-right: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.pa-resend-code {
  position: absolute;
  top: 24px;
  right: 0;
  color: $han-blue-60;
  background-color: transparent;
  &:hover {
    text-decoration: underline;
  }

  &.disable {
    color: $disabled-03;
    opacity: 0.5;
    pointer-events: none;
  }
}

.pa-timer {
  font-size: 11px;
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(16px);
  @include letterspacing(0.24px);
  @include respond(small) {
    font-size: 12px;
  }

  .pa-time {
    width: 30px;
    @include respond(medium) {
      width: 34px;
    }
  }

  @include respond(medium) {
    font-size: 12px;
  }
}
