.icn {
  display: inline-block;
  //font-size: 0;
  align-items: center;
  background: transparent no-repeat center center;
  background-size: 100% 100%;

  svg {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    position: static;
    right: 1rem;
  }

  &.icn-cont {
    flex-shrink: 0;
    height: 1rem;
    position: absolute;
    right: 1rem;
    width: 1rem;
  }
}

input:focus {
  outline: none;
}

input:focus-visible {
  outline: none;
}

.badge {
  background-color: $icon-06;
  width: 19px;
  height: 16px;
  @include fontSize(12px);
  color: $text-04;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 2px;
  right: 8px;
  transform: translateY(-50%) translateX(50%);

  @include fontSize(12px);
  @include fontFamily('LB-Nu-Bold');
  @include lineheight(16px);
  @include letterspacing(0.24px);
  @include respond(medium) {
    width: 22px;
    height: 18px;
    top: 1px;
    right: 0;
  }
}

//blend mode

// .blend-mode {
//   mix-blend-mode: darken;
// }

//divider

.line {
  width: 100%;
  height: 1px;
  background-color: $ui-03;
}

//display flex
.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

@include respond(medium) {
  .d-block-md {
    display: block !important;
  }
  .d-none-md {
    display: none !important;
  }
}
.flex-1 {
  flex: 1;
}
.flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-content-end {
  display: flex;
  justify-content: end;
}

.flex-items-start {
  display: flex;
  align-items: flex-start;
}

.flex-content-between {
  display: flex;
  justify-content: space-between;
}

.flex-items-center {
  display: flex;
  align-items: center;
}

.flex-items-end {
  display: flex;
  align-items: flex-end;
}

.flex-items-start {
  display: flex;
  align-items: flex-start;
}

.flex-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-content-end {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

.flex-content-start {
  display: flex;
  align-items: flex-start;
  justify-content: start;
}

.flex-content-start-center {
  display: flex;
  align-items: center;
  justify-content: start;
}
.flex-content-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-content-between-start {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.gap-24 {
  gap: 1.5rem;
}
//text align

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pa-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}
// overflow contnet

.bx--end-line {
  width: 100%;
  display: inline-block;
  direction: ltr;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
// scrollbar

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #e5e5e5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #b7b7b7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #b7b7b7;
}

//img responsive

.img-fluid {
  width: 100%;
  height: auto;
}

//width %
.wp-30 {
  width: 30px;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-50 {
  width: 50%;
}

.w-53 {
  width: 53% !important;
}

.w-60 {
  width: 60%;
}

.w-85 {
  width: 85%;
}

.w-100 {
  width: 100%;
}

.w-740 {
  width: 740px !important;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
// margin

.mh-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.h-100 {
  height: 100% !important;
}
//some class
.underline {
  text-decoration: underline !important;
}

.is-danger {
  color: $text-07-danger;
}
.is-success {
  color: $success-default;
}
.is-white {
  color: $text-04;
}

.is-black {
  color: $black;
}
.text-01 {
  color: $text-01;
}

.text-02 {
  color: $text-02;
}

.text-light-01 {
  color: $text-light-01 !important;
}

.text-light-02 {
  color: $text-light-02 !important;
}

.link-01 {
  color: #1152c6;
  transition: 0.3s;

  &:hover {
    text-decoration: underline;
  }
}

.visible {
  display: block;
}
.hidden {
  display: none;
}

.pointer {
  cursor: pointer;
}

.po-r {
  position: relative;
}
.text-indent {
  text-indent: -99999px;
}

.text-04-danger {
  color: $text-04-danger !important;
}
.ov-hidden-scroll {
  overflow: auto;
  padding-right: 0;

  @include respond(medium) {
    overflow: hidden;
    padding-right: 6px;
  }
}

.vis-hidden {
  visibility: hidden;
}

.vis-visibile {
  visibility: visible;
}

.p-0 {
  padding-left: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.w-break {
  word-break: break-all;
}

.grecaptcha-badge {
  visibility: hidden;
}

.maxc-w {
  width: max-content;
}

.no-border {
  border: none !important;
}

.pa-email-input {
  .pa-input-checkmark-filled-icon {
    right: 44px !important;
  }
}

// opacity

.opa-5 {
  opacity: 0.5;
}
