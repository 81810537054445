//loading
@-webkit-keyframes push {
  50% {
    left: 50px;
  }
}

@keyframes push {
  50% {
    left: 50px;
  }
}

//loading button
@-webkit-keyframes pushmobile {
  50% {
    left: 16px;
  }
}

@keyframes pushmobile {
  50% {
    left: 16px;
  }
}
//loading button small
@-webkit-keyframes pushmobilesmall {
  50% {
    left: 10px;
  }
}

@keyframes pushmobilesmall {
  50% {
    left: 10px;
  }
}

//balance button
@-webkit-keyframes pushbalance {
  50% {
    left: 8px;
  }
}

@keyframes pushbalance {
  50% {
    left: 8px;
  }
}
