.container-breadcrumb {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb {
  @include fontSize(14px);
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(18px);
  @include letterspacing(0.16px);
  display: inline;
  @include respond(medium) {
    display: flex;
    //flex-wrap: wrap;
  }

  //skeleton
  &.breadcrumb-skeleton {
    height: 1rem;
    .breadcrumb-item {
      &.bx--skeleton__text {
        width: 6.25rem !important;
      }
    }
  }
  .breadcrumb-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;

    // &::after {
    //   margin-left: 0.5rem;
    //   color: $icon-02;
    //   content: '/';
    // }
    &:last-child {
      span {
        display: none;
      }
    }
    .breadcrumb-link {
      color: $link-02;
      white-space: nowrap;
      transition: 0.3s;

      &:hover {
        color: $text-01;
        text-decoration: underline;
      }

      &:active {
        color: $text-01;
        text-decoration: underline;
      }
    }

    &.breadcrumb-item-active {
      // &:after {
      //   content: '';
      // }

      .breadcrumb-link {
        color: $text-02;
        cursor: auto;

        &:hover {
          // color: $text-01;
          text-decoration: none;
        }
      }
    }

    &.breadcrumb-overflow {
      margin-right: 0;

      &:after {
        content: '';
      }

      .breadcrumb-overflow-menu {
        position: relative;
        width: 1.25rem;
        height: 1.125rem;
        border: 1px solid transparent;
        outline-offset: -2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: outline 110ms cubic-bezier(0, 0, 0.38, 0.9),
          background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
        border: none;
        background: none;

        &__icon {
          position: relative;
          -webkit-transform: translateY(4px);
          transform: translateY(4px);
          width: 1rem;
          height: 1rem;
          fill: $icon-02;
        }
      }
    }
  }

  &.breadcrumb-skeleton {
    .breadcrumb-link {
      position: relative;
      padding: 0;
      border: none;
      background: $skeleton-01;
      box-shadow: none;
      pointer-events: none;
      width: 85px;
      height: 8px;
    }
  }
}
