.pa-help-content {
  width: auto;
  margin: 0 auto;

  @include respond(medium) {
    //  padding-bottom: 130px;
    width: 768px;
  }

  ol {
    list-style-type: revert !important;
    padding-left: 20px !important;
  }
  ul {
    list-style-type: unset !important;
    padding-left: 0 !important;

    @include respond(medium) {
      padding-left: 20px !important;
    }
  }
}
