.container {
  margin-left: 0;
  margin-top: 0;
  min-height: auto;
  position: relative;
  transition: 0.11s ease;
  width: 100%;
  //  padding-bottom: 64px;

  @include respond(medium) {
    margin-top: 24px;
    //  min-height: calc(100vh - 48px);
  }
}

.bx--grid {
  max-width: 82rem !important;

  @include respond(xlarge) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

//not load categories

.popularproviders-view {
  .all-categories {
    &__row {
      .pa-block {
        &__box {
          &--favorites {
            width: 49.5%;
            margin-bottom: 32px;
            //  margin-right: 16px;
            height: 63px;
            background-color: $enable-03;
            border-radius: 8px;
            padding: 0 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s linear;
            overflow: hidden;
            cursor: pointer;

            &:hover {
              background-color: $hover-03;
            }

            &.active {
              background-color: $active-03;
            }

            &.selected {
              background-color: $active-03;
            }

            &:nth-child(2n) {
              margin-right: 0;
              margin-left: 0;
            }
            @media screen and (max-width: 860px) {
              width: 49.4%;
            }
            @media screen and (max-width: 672px) {
              width: 48.66%;
            }
            @media screen and (max-width: 629px) {
              width: 48.88%;
            }
            @media screen and (max-width: 545px) {
              width: 49%;
            }
            @media screen and (max-width: 432px) {
              width: 48.88%;
            }
            @media screen and (max-width: 385px) {
              width: 48.44%;
            }

            @media screen and (max-width: 372px) {
              width: 48.44%;
            }
            @media screen and (max-width: 345px) {
              width: 48.33%;
            }
            @media screen and (max-width: 330px) {
              width: 48.22%;
            }

            @include respond(medium) {
              width: calc(100% / 4 - 32px);
              height: 134px;
              margin-bottom: 32px;
              margin-right: 16px;
              margin-left: 16px;

              &:nth-child(2n) {
                margin-right: 16px;
                margin-left: 16px;
              }
            }
            @include respond(large) {
              // width: 288px;
              height: 134px;
              margin-bottom: 32px;
              margin-right: 16px;
              margin-left: 16px;

              &:nth-child(2n) {
                margin-right: 16px;
                margin-left: 16px;
              }
            }

            img {
              width: 131px;
              height: 63px;
              // mix-blend-mode: darken;

              @include respond(medium) {
                width: 288px;
                height: 134px;
              }
            }
          }
        }
      }
    }
  }
}

.all-categories {
  &__content {
    .bx-row-main {
      padding-top: 44px;

      @include respond(medium) {
        padding-top: 96px;

        .bx--col-lg-8 {
          max-width: 62.5%;
          flex: 0 0 62.5%;
        }
        .bx--col-lg-4 {
          max-width: 37.5%;
          flex: 0 0 37.5%;
        }
      }
    }

    h1 {
      color: $text-01;
    }

    p {
      color: $text-02;
      margin-bottom: 16px;
    }

    .bx--col-favorites {
      padding-left: 16px;
      margin-top: 32px !important;
    }

    .productive-heading-04 {
      @include fontSize(20px);
      @include fontFamily('LB-Nu-Semibold');
      @include lineheight(28px);
      @include letterspacing(0.16px);
    }

    .body-long-02 {
      @include fontSize(12px);
      @include fontFamily('LB-Nu-Medium');
      @include lineheight(18px);
      @include letterspacing(0.24px);
    }

    @include respond(medium) {
      .bx--col-favorites {
        padding-left: 32px;
        margin-top: 0 !important;
      }
      p {
        margin-bottom: 32px;
      }
      .productive-heading-04 {
        @include fontSize(28px);
        @include fontFamily('LB-Nu-Semibold');
        @include lineheight(36px);
        @include letterspacing(0);
      }

      .body-long-02 {
        @include fontSize(14px);
        @include fontFamily('LB-Nu-Medium');
        @include lineheight(20px);
        @include letterspacing(0.16px);
      }
    }

    @media screen and (max-width: 1076px) {
      .bx--col-favorites {
        padding-left: 16px;
        margin-top: 0;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    // flex-direction: column;
    flex-wrap: wrap;
    //justify-content: space-between;

    .pa-block {
      &__box {
        width: 47%;
        margin-bottom: 16px;
        margin-right: 8px;
        height: 136px;
        background-color: $enable-03;
        border-radius: 10px;
        padding: 24px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        float: left;
        transition: all 0.3s linear;
        overflow: hidden;
        cursor: pointer;

        &:hover {
          background-color: $hover-03;
        }

        &.active {
          background-color: $active-03;
        }

        &.selected {
          background-color: $active-03;
        }

        &:nth-child(1) {
          flex-direction: row;
          padding: 0 28px;

          span.productive-heading-02 {
            // order: unset;
          }
        }

        &:nth-child(2n) {
          margin-right: 0;
          margin-left: 8px;
        }

        span.productive-heading-02 {
          @include fontSize(12px);
          @include fontFamily('LB-Nu-Medium');
          @include lineheight(16px);
          @include letterspacing(0.24px);
          order: 2;
          text-align: center;
        }

        .icn {
          width: 48px;
          height: 48px;
          order: 1;
          margin-bottom: 8px;

          &.logo-lb {
            width: 109px;
            height: 20px;
            margin-bottom: 15px;
          }

          svg {
            width: 100% !important;
            height: 100% !important;
            pointer-events: none !important;
          }

          @include respond(medium) {
            margin-bottom: 8px;
          }
        }

        &.card-100 {
          width: 100%;
          margin-right: 0;
          margin-left: 0;
        }

        &.is-liberty {
          span.productive-heading-02 {
            order: unset;
          }

          .icn {
            order: unset;
          }
        }
        @media screen and (max-width: 672px) {
          width: 48.66%;
        }
        @media screen and (max-width: 629px) {
          width: 48.44%;
        }
        @media screen and (max-width: 545px) {
          width: 48%;
        }
        @media screen and (max-width: 432px) {
          width: 47.66%;
        }
        @media screen and (max-width: 372px) {
          width: 47.44%;
        }
        @media screen and (max-width: 345px) {
          width: 47.33%;
        }
        @media screen and (max-width: 330px) {
          width: 47.22%;
        }
        @include respond(small) {
          width: calc(96.6666% / 2);
          height: 128px;
          padding: 0 24px;
          margin-bottom: 16px;
          margin-right: 8px;
          flex-direction: row;

          &:nth-child(1) {
            flex-direction: row;
            padding: 0 24px;

            span.productive-heading-02 {
              order: unset;
            }
          }

          &:nth-child(2n) {
            margin-right: 0;
            margin-left: 8px;
          }

          .icn {
            width: 64px;
            height: 64px;
            order: unset;
            margin-bottom: 0;
          }

          span.productive-heading-02 {
            @include fontSize(16px);
            @include fontFamily('LB-Nu-Semibold');
            @include lineheight(24px);
            @include letterspacing(0);
            order: unset;
            text-align: unset;
          }

          &.card-100 {
            width: calc(96.6666% / 2);
            // margin-right: 12px;
          }

          &.is-liberty {
            margin-right: 8px;
          }
        }

        @include respond(medium) {
          width: calc(95.6666% / 2);
          height: 128px;
          padding: 0 24px;
          margin-bottom: 24px;
          margin-right: 12px;
          &:nth-child(1) {
            span.productive-heading-02 {
              span {
                display: block;
              }
            }
          }
          &:nth-child(2n) {
            margin-right: 0;
            margin-left: 12px;
          }
          .icn {
            width: 64px;
            height: 64px;
            order: unset;
            margin-bottom: 0;
          }
          span.productive-heading-02 {
            @include fontSize(14px);
            @include fontFamily('LB-Nu-Semibold');
            @include lineheight(20px);
            @include letterspacing(0.16px);
            order: unset;
            text-align: unset;
          }
          &.card-100 {
            width: calc(95.6666% / 2);
            margin-right: 8px;
          }
        }
        @include respond(large) {
          width: calc(96.6666% / 2);
          height: 128px;
          padding: 0 24px;
          margin-bottom: 24px;
          margin-right: 12px;
          &:nth-child(1) {
            span.productive-heading-02 {
              span {
                display: block;
              }
            }
          }
          &:nth-child(2n) {
            margin-right: 0;
            margin-left: 12px;
          }
          .icn {
            width: 64px;
            height: 64px;
            order: unset;
            margin-bottom: 0;
          }
          span.productive-heading-02 {
            @include fontSize(16px);
            @include fontFamily('LB-Nu-Semibold');
            @include lineheight(24px);
            @include letterspacing(0);
            order: unset;
            text-align: unset;
          }
          &.card-100 {
            width: calc(96.6666% / 2);
            margin-right: 8px;
          }
        }

        &--favorites {
          width: 46.5%;
          margin-bottom: 16px;
          margin-right: 8px;
          height: 63px;
          background-color: $enable-03;
          border-radius: 8px;
          padding: 0 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s linear;
          overflow: hidden;
          cursor: pointer;

          &:hover {
           // background-color: $hover-03;
          }

          &.active {
            background-color: $active-03;
          }

          &.selected {
            background-color: $active-03;
          }

          &:nth-child(2n) {
            margin-right: 0;
            margin-left: 8px;
          }
          @media screen and (max-width: 672px) {
            width: 48.66%;
            height: 95px;
          }
          @media screen and (max-width: 629px) {
            width: 48.44%;
            height: 95px;
          }
          @media screen and (max-width: 545px) {
            width: 48%;
          }
          @media screen and (max-width: 432px) {
            width: 47.66%;
            height: 63px;
          }
          @media screen and (max-width: 372px) {
            width: 47.44%;
          }
          @media screen and (max-width: 345px) {
            width: 47.33%;
          }
          @media screen and (max-width: 330px) {
            width: 47.22%;
          }
          @include respond(small) {
            width: calc(96.6666% / 2);
            height: 95px;
            margin-bottom: 16px;
            margin-right: 8px;
            border-radius: 10px;

            &:nth-child(2n) {
              margin-right: 0;
              margin-left: 8px;
            }
          }
          @include respond(medium) {
            width: calc(92% / 2);
            height: 95px;
            margin-bottom: 27px;
            margin-right: 12px;

            &:nth-child(2n) {
              margin-right: 0;
              margin-left: 12px;
            }
          }
          @include respond(large) {
            width: calc(94.445% / 2);
            margin-bottom: 27px;
            margin-right: 11px;

            &:nth-child(2n) {
              margin-right: 0;
              margin-left: 12px;
            }
          }

          img {
            width: 131px;
            height: 63px;
            // mix-blend-mode: darken;

            @include respond(medium) {
              width: 204px;
              height: 95px;
            }
          }
        }
      }

      &__box--mobile-fill {
        min-height: 124px;
        height: auto;
        background-color: $ui-08;
        color: $inverse-text-01;
        padding: 22px 24px 24px 24px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        order: -1;

        &:hover {
          background-color: $ui-08;
        }

        &.active {
          background-color: $ui-08;
        }

        &.selected {
          background-color: $ui-08;
        }

        span.productive-heading-02 {
          order: unset;
          @include fontSize(14px);
          @include fontFamily('LB-Nu-Bold');
          @include lineheight(20px);
          @include letterspacing(0.16px);
          span {
            padding-top: 1px;
            @include respond(medium) {
              padding-top: 0;
            }
          }
        }

        .icn {
          width: 20px;
          height: 20px;
          order: unset;
          margin-bottom: 0;

          svg {
            width: 100% !important;
            height: 100% !important;
            pointer-events: none !important;
          }
        }

        .mobile-fill-group {
          display: flex;
          align-items: center;
          //margin-top: 16px;
          //order: 2;
          //  flex-direction: column;
          width: 100%;
          .pa-text-input {
            padding: 0.6875rem 0.75rem;
            &:focus {
              outline: 2px solid transparent;
              outline-offset: -2px;
              border: 2px solid transparent;
              box-shadow: none;
              transition: none;
              padding: 0.6875rem 0.6875rem;
            }
            &:active {
              outline: 2px solid transparent;
              outline-offset: -2px;
              box-shadow: none;
              transition: none;
            }

            &__field-wrapper {
              width: 100%;

              @include respond(medium) {
                width: 100%;
              }
            }
          }
          .wrapper-group {
            //  width: 100%;
            display: flex;
            align-items: center;
            //margin-top: 8px;
            // margin-left: 0;

            .pa-text-input__field-outer-wrapper {
              margin: 0 8px 0 0;
            }
            .pa-text-input {
              padding-right: 2.5rem;
            }
            .btn-next-m {
              display: flex;
            }

            .btn-next-w {
              // display: none;
              // padding-left: 1rem;
              // padding-right: 1rem;
            }

            @media (min-width: 375px) {
              .pa-text-input__field-outer-wrapper {
                margin: 0 8px 0 8px;
              }

              .btn-next-m {
                //  display: none;
              }

              .btn-next-w {
                display: flex;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
              }
            }

            @include respond(medium) {
              margin-left: 8px;

              .pa-text-input__field-outer-wrapper {
                margin: 0 8px 0 8px;

                .btn-next-m {
                  display: none;
                }

                .btn-next-w {
                  display: flex;
                  padding-left: 0.5rem;
                  padding-right: 0.5rem;
                }
              }
            }
          }

          & > .pa-text-input {
            width: 100%;

            @include respond(medium) {
              width: 100%;
            }
          }

          // @media (min-width: 375px) {
          //   flex-direction: row;

          //   .wrapper-group {
          //     width: auto;
          //     margin-top: 0;
          //     margin-left: 8px;
          //   }
          // }
        }

        @include respond(small) {
          margin-left: 8px;
          order: unset;
        }

        @include respond(medium) {
          height: 128px;
          padding: 24px;

          &.card-100 {
            margin-left: 16px;
            margin-right: 0;
          }
        }
      }
    }

    .top-row {
      display: flex;
      flex-direction: column;
      width: 100%;

      .pa-block__box {
        width: 100%;
        order: 2;
      }

      .pa-block__box--mobile-fill {
        width: 100%;
        order: 1;
        margin-left: 0;
      }

      @include respond(medium) {
        display: flex;
        flex-direction: row;

        .pa-block__box {
          order: 1;
        }
        .pa-block__box--mobile-fill {
          order: 2;
          margin-left: 12px;
        }
      }
    }
  }
}

//loader

.loader-out {
  display: flex;
  align-items: center;
  justify-content: center;
  &.full-size {
    position: absolute;
    top: 0;
    z-index: 3;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.4);
  }

  &.fixed {
    position: fixed;
  }

  &.btn-loader {
    position: absolute;
    top: 54%;
    left: 0;
    right: 0;

    .loader {
      width: 2rem;
      height: 0.375rem;

      &::before {
        width: 1rem;
        height: 0.375rem;
        left: 0;
        -webkit-animation: pushmobile 1.5s infinite linear;
        animation: pushmobile 1.5s infinite linear;
      }
    }
  }
  &.balance-loader {
    .loader {
      width: 1.25rem;
      height: 0.375rem;
      background-color: $ui-05;
      &::before {
        width: 0.625rem;
        height: 0.375rem;
        background-color: $ui-08;
        left: 0;
        -webkit-animation: pushbalance 1.5s infinite linear;
        animation: pushbalance 1.5s infinite linear;
      }
    }
  }
}

.loader {
  box-sizing: border-box;
  width: 72px;
  height: 8px;
  padding: 0 4px;
  border-radius: 4px;
  background-color: #ebebed;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 24px;
    height: 8px;
    border-radius: 2px;
    background-color: #cdcdcf;
    left: 5px;
    -webkit-animation: push 1.5s infinite linear;
    animation: push 1.5s infinite linear;
  }
}

// transfer block
.pa-transfer-block {
  padding: 48px 0;
  background-color: #f5f5f7;
  border-bottom: 1px solid #ebebeb;
  margin-top: 96px;
  margin-bottom: 0;

  .text-block {
    text-align: left;

    .productive-heading-04 {
      @include fontSize(20px);
      @include fontFamily('LB-Nu-Semibold');
      @include lineheight(28px);
      @include letterspacing(0.16px);
    }

    .body-long-02 {
      @include fontSize(12px);
      @include fontFamily('LB-Nu-Medium');
      @include lineheight(18px);
      @include letterspacing(0.24px);
    }
  }

  .p2p-container {
    margin: 56px 0 0 0;
    .card-block {
      @include respond(medium) {
        .card-block {
          width: 583px !important;
        }
      }
    }
  }

  @include respond(medium) {
    padding: 32px 0 0;
    background-color: transparent;
    margin-top: 64px;
    margin-bottom: 64px;
     border-bottom: none;

    .text-block {
      text-align: center;

      .productive-heading-04 {
        @include fontSize(28px);
        @include fontFamily('LB-Nu-Semibold');
        @include lineheight(36px);
        @include letterspacing(0);
      }

      .body-long-02 {
        @include fontSize(14px);
        @include fontFamily('LB-Nu-Medium');
        @include lineheight(20px);
        @include letterspacing(0.16px);
      }
    }
  }
}

.chat-container {
  background-color: $enabled-tertiary-01;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 2000;
  color: $icon-05;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
  }

  @include respond(medium) {
    bottom: 64px;
    right: 64px;
  }
}

// overlay search focused

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: $overlay-01;
}

.card-block {
  width: 583px !important;
}
