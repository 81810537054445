.content-in {
  max-width: 572px;
  margin: 60px auto 0;
  padding: 0 0;
  .transfer-icon {
    //padding-top: 64px;
    @include respond(medium) {
      padding-top: 0;
    }
  }
  .transfer-heading {
    .productive-heading-03 {
      @include fontSize(16px);
      @include fontFamily('LB-Nu-Semibold');
      @include lineheight(22px);
      @include letterspacing(0.16px);

      @include respond(medium) {
        @include fontSize(20px);
        @include fontFamily('LB-Nu-Semibold');
        @include lineheight(28px);
        @include letterspacing(0.16px);
      }
    }

    .transaction-code {
      padding-bottom: 32px;
      @include respond(medium) {
        padding-bottom: 0;
      }
    }
  }

  .line {
    display: none;

    @include respond(medium) {
      display: block;
    }
  }

  .transfer-row {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      width: 160px;
      // margin-right: 16px;
      text-align: center;
      //   margin-bottom: 16px;
      &:first-child {
        width: 200px;
      }
    }
    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        width: 160px;
        text-align: center;
        // margin-right: 16px;

        &:first-child {
          width: 200px;
        }
      }
    }
    @include respond(medium) {
      margin-top: 0;
      justify-content: space-between;
    }
  }

  .transfer-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icn-bg {
    width: 64px;
    height: 64px;
    background-color: #f35815 !important;
    border-radius: 50%;
    padding: 16px 0;

    svg {
      width: 32px !important;
      height: 32px !important;
      color: #fff;
    }
  }

  @include respond(medium) {
    padding: 0 16px;
  }
}
