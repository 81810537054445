.providers {
  &__box {
    width: 161px;
    height: 75px;
    background-color: $enable-03;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;
    overflow: hidden;

    &:hover {
     // background-color: $hover-03;
    }

    &.active {
      background-color: $active-03;
    }

    &.selected {
      background-color: $active-03;
    }

    img {
      width: 161px;
      height: 75px;
    }
  }
}
