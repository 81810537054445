.pa-privacypolicy-block {
  ol {
    counter-reset: item;
    list-style-type: revert !important;

    li {
      padding-bottom: 24px;

      ol {
        padding-top: 16px;

        @include respond(medium) {
          padding-top: 24px;
        }

        li {
          padding-bottom: 8px;
          @include respond(medium) {
            padding-bottom: 24px;
          }
        }
      }
    }
  }
  ul {
    list-style-type: unset !important;
  }
  li {
    display: block;
  }

  li:before {
    content: counters(item, '.') '.';
    counter-increment: item;
    padding-right: 8px;
  }
}
