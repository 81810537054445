.bx--skeleton {
  &__placeholder {
    &::before {
      background: $skeleton-01;
      border-radius: 8px;
    }
    &.pa-block__box--favorites {
      &::before {
        border-radius: 10px;
      }
    }
  }

  &__text {
    border-radius: 2px;
    overflow: hidden;
    margin-bottom: 1rem !important;

    &::before {
      background: $skeleton-01;
      border-radius: 2px;
    }

    &.pa-block__favorites-in {
      aspect-ratio: 19 / 9;
    }
    &.pa-banner-left {
      width: 256px !important;
      height: 390px;
      border-radius: 10px;
    }
     &.pa-balance-right {
      width: 256px !important;
      height: 390px;
      border-radius: 10px;
    }
  }
}
