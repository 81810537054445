.form-group {
  margin-bottom: 1.5rem;
}

//label
.label-01 {
  margin-bottom: 0.5rem;
  display: inline-block;
  color: $text-01;
}

//input

.pa-text-input {
  @include fontSize(14px);
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(18px);
  @include letterspacing(0.16px);
  width: 100%;
  height: 3rem;
  padding: 0.875rem 1rem;
  border: 1px solid $light-grey-border;
  background-color: $field-01;
  border-radius: 6px;
  color: $text-01;
  // transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9),
  //   outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);

  &::placeholder {
    @include fontSize(14px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(18px);
    @include letterspacing(0.16px);
    color: $text-03;
  }

  &::-webkit-input-placeholder {
    @include fontSize(14px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(18px);
    @include letterspacing(0.16px);
    color: $text-03;
  }

  &::-moz-placeholder {
    @include fontSize(14px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(18px);
    @include letterspacing(0.16px);
    color: $text-03;
  }

  &:-ms-input-placeholder {
    @include fontSize(14px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(18px);
    @include letterspacing(0.16px);
    color: $text-03;
  }

  &:-moz-placeholder {
    @include fontSize(14px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(18px);
    @include letterspacing(0.16px);
    color: $text-03;
  }

  &:focus {
    border: 2px solid $focus-02;
    outline: none;
    padding: 0.875rem 15px;
  }

  &:active {
    border: 2px solid $focus-02;
    outline: none;
    padding: 0.875rem 15px;
  }

  &:disabled {
    background-color: $disabled-04;
    cursor: not-allowed;
    border: 1px solid $field-disabled-border;
    outline: none;
    color: $disabled-03;

    &::placeholder {
      color: $text-disabled-01;
    }
  }

  &.filled {
    border: 1px solid $filed-filled;
  }

  &__invalid {
    box-shadow: none;
    padding-right: 2.75rem;
    border: 2px solid $error-border-01;
    outline: none;
    padding: 0.875rem 15px;
  }

  &__warning {
    box-shadow: none;
    outline: 2px solid $light-grey-border;
    outline-offset: -2px;
  }

  &__field-outer-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;

    .pa-form-requirement {
      display: block;
      @include fontSize(12px);
      @include fontFamily('LB-Nu-Semibold');
      @include lineheight(16px);
      @include letterspacing(0.24px);
      max-height: 12.5rem;
      overflow: visible;
      color: $enabled-danger-01;
      padding-top: 4px;
    }
  }

  &__field-wrapper {
    display: flex;
    position: relative;
    width: 100%;

    .pa-input-invalid-icon {
      fill: $enabled-danger-01;
      color: $enabled-danger-01;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .pa-input-invalid-icon {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }

    .pa-input-warning-icon {
      fill: $support-03;
      color: $support-03;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .pa-input-warning-icon {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }

    .pa-input-checkmark-filled-icon {
      fill: $green;
      color: $green;
      width: 1.25rem;
      height: 1.25rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    .pa-input-checkmark-filled-icon {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }

    .pa-input-symbol-icon {
      fill: $text-01;
      color: $text-01;
    }

    .pa-input-symbol-icon {
      position: absolute;
      right: 1rem;
      top: 53%;
      transform: translateY(-50%);
    }

    @include respond(medium) {
      width: 368px;
    }
  }

  &.pa-text-input--md {
    height: 40px;
  }
}
//select
.pa-select-input__wrapper {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;

  .pa-text-select__invalid-icon {
    position: absolute;
    right: 3.125rem;
    font-size: 0;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
.pa-text-select {
  @include fontSize(14px);
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(18px);
  @include letterspacing(0.16px);
  border: 1px solid transparent;
  outline-offset: -2px;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  border: 1px solid $light-grey-border;
  background-color: $field-01;
  border-radius: 6px;
  color: $text-01;
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9),
    outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

select {
  background: url('../../images/icons/chevron-down.svg') no-repeat 96.5% center;
  -webkit-appearance: none;
}

select:invalid {
  color: $text-03;
  border: 1px solid $error-border-01;
  outline-offset: -2px;
}

select option:first-child {
  //  color: $text-03;
}

select:invalid option:not(:first-child) {
  color: $text-01;
}

//form skeleton
.bx--form-item {
  margin-bottom: 32px;
  @include respond(medium) {
    margin-bottom: 48px;
  }
}
.bx--label {
  &.bx--skeleton {
    border-radius: 2px;
    overflow: hidden;
    height: 8px;
    &::before {
      background: $skeleton-01;
      border-radius: 2px;
    }
  }
}
.bx--text-input {
  &.bx--skeleton {
    border-radius: 6px;
    overflow: hidden;
    &::before {
      background: $skeleton-01;
      border-radius: 6px;
    }
  }
}

//checkbox
.bx--checkbox-label {
  &::before {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 4px;
    border: 1px solid #cdcdcf;
  }

  &::after {
    top: 0.5625rem;
    left: 0.5625rem;
  }

  &:disabled {
    &::before {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 4px;
      border: 1px solid $icon-04;
      background-color: $icon-04;
    }

    &::after {
      top: 0.5625rem;
      left: 0.5625rem;
    }
  }

  &:hover {
    a {
      text-decoration: underline;
    }
  }
}

.bx--checkbox-label-text {
  padding: 0.125rem 0 0 0.5625rem;
  @include fontSize(14px);
  @include fontFamily('LB-Nu-Medium');
  @include lineheight(18px);
  @include letterspacing(0.16px);
  display: flex;
  align-items: center;
}

.bx--checkbox:focus + .bx--checkbox-label::before,
.bx--checkbox-label__focus::before,
.bx--checkbox:checked:focus + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='true'].bx--checkbox-label__focus::before,
.bx--checkbox:indeterminate:focus + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='mixed'].bx--checkbox-label__focus::before {
  outline: none !important;
}

//pa-input-group

.pa-input-group {
  .mob-btn {
    &.btn-load {
      width: 40px !important;
    }
  }
  .btn {
    width: auto;
    position: absolute;
    right: 4px;
    top: 4px;

    @include respond(medium) {
      width: 101px;
    }
  }
  .pa-text-input {
    padding-right: 90px;
    &::placeholder {
      font-size: 0.75rem;
    }
    @include respond(medium) {
      padding-right: 120px;
      &::placeholder {
        font-size: 0.875rem;
      }
    }
  }
}
