.pa-sidebar {
  max-width: 256px;
  margin: 0 0 0 0;

  @include respond(medium) {
    margin: 0 20px 0 0;
  }
}

.pa-sidebar-menu {
  padding-bottom: 32px;
  .bx--skeleton__text {
    margin-bottom: 0;
  }
  .pa-sidebar {
    &__list {
      padding: 12px 16px 10px;
      transition: all 0.3s linear;
      border-radius: 6px;
      cursor: pointer;

      &:not(:first-child) {
        margin: 2px 0;
      }

      &:hover {
        background-color: $hover-02;
      }

      &.active {
        background-color: $hover-02;
      }
    }

    &__link {
      @include fontSize(14px);
      @include fontFamily('LB-Nu-SemiBold');
      @include lineheight(18px);
      @include letterspacing(0.16px);
      color: $text-01;
      cursor: pointer;
    }
  }
}

.pa-banner-block {
  // padding-top: 32px;

  img {
    width: 256px;
    height: 390px;
    border-radius: 10px;
  }
}
