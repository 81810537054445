.pa-react-datepicker-wrapper {
  width: 100%;
  @include respond(medium) {
    width: 318px;
  }

  label {
    display: block;
    @extend .label-01;
  }

  .react-datepicker-wrapper {
    width: 100%;
    @include respond(medium) {
      width: 318px;
    }

    .react-datepicker__input-container input {
      caret-color: transparent;
      width: 100%;
      @extend .body-short-02;
      height: 48px;
      border-radius: 6px;
      padding: 0.875rem 15px;
      border: 1px solid $light-grey-border;
      background: url(../../images/icons/datepicker-calendar.svg) no-repeat bottom 50% right 16px;

      &.invalid {
        outline-offset: -2px;
        outline: 2px solid $error-border-01;;
        border: none;
        background-image: url(../../images/icons/input-invalid.svg), url(../../images/icons/datepicker-calendar.svg);
        background-repeat: no-repeat, no-repeat;
        background-position: bottom 50% right 44px, bottom 50% right 16px;
        background-size: 21px, auto;
      }

      &::placeholder {
        color: $text-03;
      }

      &:focus {
        outline: 2px solid $focus-02;
        outline-offset: -2px;
      }
    }
  }

  .react-datepicker__tab-loop {
    position: relative;
  }

  .react-datepicker__tab-loop .react-datepicker-popper {
    border-radius: 8px;
    box-shadow: 0 0 15px -7px rgba(0, 0, 0, 0.32), 0 24px 38px 3px rgba(0, 0, 0, 0.12);
    transform: translate(0, 0) !important;

    .react-datepicker {
      border: none;
      border-radius: 8px;
    }

    .react-datepicker__navigation {
      height: 40px;
      width: 40px;
      top: 4px;

      &--previous {
        left: 4px;
      }

      &--next {
        right: 4px;
      }

      &:hover {
        background: #e5e5e5;
        border-radius: 8px;
      }

      .react-datepicker__navigation-icon {
        &--previous {
          right: 0;
          top: 1px;
        }

        &--next {
          left: 0;
          top: 1px;
        }

        &::before {
          border-width: 1.4px 1.4px 0 0 !important;
          border-color: black;
          height: 10px;
          width: 10px;
        }
      }
    }

    .react-datepicker__triangle {
      display: none;
    }
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: $background-white;
      border: none;
      padding: 4px 0 0 0;

      .react-datepicker__header__dropdown {
        @extend .button-and-link-02;
      }

      .react-datepicker__month-dropdown-container {
        .react-datepicker__month-dropdown {
          background: $background-white;
        }

        .react-datepicker__month-read-view {
          color: $han-blue-60;
          padding-block: 10px;
          visibility: visible !important;

          &--down-arrow {
            border-width: 1px 1px 0 0;
            border-color: black;
            height: 5px;
            width: 5px;
            top: 14px;
          }
        }

        .react-datepicker__month-option {
          padding-block: 6px;
          color: $text-light-02;

          :hover {
            background: #e5e5e5;
          }

          &--selected_month {
            color: black;
          }
        }
      }

      .react-datepicker__year-dropdown-container {
        .react-datepicker__year-dropdown {
          background: $background-white;
          height: fit-content;

          &.react-datepicker__year-dropdown--scrollable {
            max-height: 263px;
          }
        }

        .react-datepicker__year-read-view {
          color: $han-blue-60;
          padding-block: 10px;
          visibility: visible !important;

          .react-datepicker__year-read-view--down-arrow {
            border-width: 1px 1px 0 0;
            border-color: black;
            height: 5px;
            width: 5px;
            top: 14px;
          }
        }

        .react-datepicker__year-option {
          color: $text-light-02;
          padding-block: 6px;

          :hover {
            background: #e5e5e5;
          }

          &--selected_year {
            color: black;
          }
        }
      }

      .react-datepicker__current-month {
        display: none;
      }

      .react-datepicker__day-names {
        margin: 12px 0;

        .react-datepicker__day-name {
          @extend .body-short-01;
          color: $text-light-02;
          margin: 0;
          min-width: 40px;

          @include respond(medium) {
            &:not(:last-child) {
              margin-right: 5px;
            }
          }
        }
      }
    }

    .react-datepicker__month {
      margin: 0 4px 8px 4px;

      .react-datepicker__week {
        .react-datepicker__day:not(:last-child) {
          @include respond(medium) {
            margin-right: 5px;
          }
        }
      }

      .react-datepicker__day {
        @extend .button-and-link-01;
        font-weight: 400;
        min-width: 40px;
        margin: 0;
        padding: 11px 0;
        border-radius: 8px;

        &:hover {
          border-radius: 8px;
        }

        &:not(.selected) {
          color: $neutral-95;
        }

        &.react-datepicker__day--today {
          padding: 11px 0 6px 0;
        }

        &.react-datepicker__day--disabled {
          color: #cfcfd3 !important;
          cursor: not-allowed;
        }

        &.react-datepicker__day--outside-month {
          color: $neutral-60;
        }

        &.react-datepicker__day--selected {
          color: $field-01;
          background-color: $neutral-95;
          border-radius: 8px;
          outline: none;
        }

        &.react-datepicker__day--keyboard-selected {
          background: transparent;
          &:hover {
            background-color: #f0f0f0;
          }
        }

        &--today::after {
          content: " ";
          display: block;
          margin: auto;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          left: calc(50% - 2.5px);
          background-color: $brand-color-60 !important;
        }
      }
    }
  }

  .text-invalid {
    font-size: 0.75rem;
    font-family: "LB-Nu-Semibold", Arial, sans-serif;
    line-height: 1rem;
    letter-spacing: 0.24px;
    color: $error-dark-09;
  }
}