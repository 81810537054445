.bx--toggle__switch {
  height: 30px;

  &:before {
    box-shadow: none !important;
    outline: none;
    height: 100%;
    transition: none;
    background-color: $neutral-20;
  }

  .bx--toggle__text--off {
    display: none;
  }

  .bx--toggle__text--on {
    display: none;
  }

  &:after {
    width: 26px;
    height: 26px;
    top: 2px;
    left: 2px;
  }
}

.bx--toggle-input__label {
  @extend .body-long-01;
  @include respond(medium) {
    @include fontSize(14px);
    @include letterspacing(0.16px);
  }
}

.bx--toggle-input:checked + .bx--toggle-input__label > .bx--toggle__switch::before {
  background-color: $success-60;
}

.bx--toggle-input:checked + .bx--toggle-input__label > .bx--toggle__switch::after {
  transform: translateX(18px);
}