.pa-about-content {
  max-width: 768px;
  padding-bottom: 50px;
  margin: 0 auto;
  color: $text-01;

  ol {
    list-style-type: revert !important;
    padding-left: 20px !important;
  }
  ul {
    list-style-type: unset !important;
    padding-left: 20px !important;
  }
  .pa-about-media {
    width: 100%;
    height: 113px;
    background-color: rgb(226, 107, 107);
    border-radius: 12px;
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 2rem;
  }

  .text-link {
    color: $link-02;
    @include fontSize(12px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(18px);
    @include letterspacing(0.24px);

    &:hover {
      color: $link-01;
      text-decoration: underline;
    }
  }

  .body-long-02 {
    @include fontSize(12px);
    @include fontFamily('LB-Nu-Medium');
    @include lineheight(18px);
    @include letterspacing(0.24px);
  }

  @include respond(small) {
    .pa-about-media {
      height: 300px;
      margin-bottom: 2rem;
    }
  }
  @include respond(medium) {
    .pa-about-media {
      height: 300px;
      margin-bottom: 2rem;
    }
    .body-long-02 {
      @include fontSize(14px);
      @include fontFamily('LB-Nu-Medium');
      @include lineheight(20px);
      @include letterspacing(0.16px);
    }

    .text-link {
      @include fontSize(14px);
      @include fontFamily('LB-Nu-Bold');
      @include lineheight(18px);
      @include letterspacing(0.16px);
    }
  }
}
